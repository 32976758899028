<template>
  <div id="lodginghome-phone">
    <div class="phone-container">
      <div id="myscroller" class="card-lodging-container">
            <div class="card-hub mt-5">
              <div class="position-relative">
                <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/calendrier.svg" style="height: 170px !important; width: 250px !important;" class="prop-img" v-set-alt>
              </div>
              <div class="card-hub-info">
                <p class="anchor mt-5">Offre valable pour tout séjour effectué entre le 1er avril 2025 et le 5 mai 2025 sur le site hortense.green</p>
              </div>
            </div>
            <div class="card-hub">
              <div class="position-relative">
                <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/validite-offre.svg" class="prop-img" v-set-alt>
              </div>
              <div class="card-hub-info">
                <p class="anchor">Offre valable pour toutes les réservations effectuées avant le 1er mars 2025</p>
              </div>
         </div>
            <div class="card-hub">
              <div class="position-relative">
                <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/offre-non-cumulable.svg" class="prop-img" v-set-alt>
              </div>
              <div class="card-hub-info">
                <p class="anchor">Offre valable pour tout séjour de 2 nuits minimum</p>
              </div>
            </div>
            <div class="card-hub">
              <div class="position-relative">
                <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/programme-de-fidelite.svg" class="prop-img" v-set-alt>
              </div>
              <div class="card-hub-info">
                <p class="anchor">Offre réservée aux membres du programme de fidélité Hortensia</p>
              </div>
            </div>
            <div class="card-hub">
          <div class="position-relative">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/nombre-nuits-minimum.svg" class="prop-img" v-set-alt>
          </div>
          <div class="card-hub-info">
            <p class="anchor">Offre non cumulable avec d’autres promotions ou offres en cours</p>
          </div>
        </div>
      </div>

      <!--</slither-slider>-->
    </div>
  </div>
</template>

<script>

// import { Splide, SplideSlide } from '@splidejs/vue-splide';


  export default {
    name: 'discount-prepositions-phone',
    // components:{ Splide, SplideSlide },
    data() {
      return {
        options: {
          arrows: 0,
          rewind : true,
          width  : '100%',
          height: 304,
          padding: {
            right: '7rem',
          },
          perMove:1,
          gap    : '1rem',
          classes:{
            pagination: 'splide__pagination  pagi-phone',
            page : 'splide__pagination__page dot-phone', 
          }
        },
      };
    },
    methods: {
      autoScroll() {
        const scroller =document.getElementById('myscroller');
        let maxScrollLeft = scroller.scrollWidth - scroller.clientWidth;

        const step = 0.5;
        const frame = () => {
          if (scroller.scrollLeft >= maxScrollLeft) {
            scroller.scrollLeft = 0;
          } else {
            scroller.scrollLeft += step;
          }
          requestAnimationFrame(frame);
        };
        frame();
      }
    },
    mounted() {
      this.autoScroll();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/tips-phone'>
.anchor{
  text-decoration: none !important;
        font-family: $Raleway;
        font-weight: 500;
        font-size: 15px;
        color: #000000;
}
.prop-img{
  height:194px !important;
  width: 260px !important;
}
.element{
    width: 100%;
}
</style>

