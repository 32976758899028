<template>
    <div id="b-phone">
      <div id="nav-phonee" class="homenav">
        <div class="nav-phone" style="margin-top: -33rem;">
          <a href="/" class="logo">
            <img src="../images/hortense blanc.svg" v-set-alt>
          </a>
        </div>
      </div>
      <video
        playsinline
        autoplay
        loop
        muted
        poster="../images/vacances-printemps-m.jpg"
        class="home_video"
        @timeupdate="updateText"
      >
        <source src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/SPRING+BREAK+-+Site+-+Mobile+-+ST.mov">
      </video>
      <div class="b-phone-container" style="margin-top: 8%">
        <div class="b-phone-header">
        <h3 class="b-title merriweather-reg-30" v-if="currentText" v-html="currentText"></h3>
      </div>
        <div>
          <MainSearchMobile></MainSearchMobile>
        </div>
      </div>
    </div>
  </template>
  
  
  
  <script>
  import MainSearchMobile from "./btns/MainSearchMobile.vue";
  
  export default {
    name: "spring-banner-phone",
    data() {
      return {
        isVideoPlaying: false, // Tracks if the video has started playing
        currentText: '',
        captions: [
          { time: [0, 3], text: "Ce <em>printemps</em>, tout change avec <em>Hortense</em>" },
          { time: [3, 6], text: "Le printemps vous invite à <em>l'évasion</em>" },
          { time: [6, 9], text: "Le printemps crée des <em>souvenirs précieux</em>" },
          { time: [9, 12], text: "Le printemps s'invite dans <em>votre assiette</em>" },
          { time: [12, 15], text: "Le printemps en toute <em>exclusivité</em>" },
          { time: [15, 18], text: "Le printemps prend <em>soin de vous</em>" },
          { time: [18, 21], text: "Le printemps vous guide vers des <em>expériences uniques</em>" },
          { time: [21, 24], text: "Avec Hortense, le printemps devient <em>inoubliable</em>" },
          { time: [24, 26], text: "Le printemps selon <em>vos envies</em>" },
          { time: [26, 30], text: "<em>Réservez votre séjour printanier dès maintenant</em>" },
        ],
      };
    },
    methods: {
      updateText(event) {
      const currentTime = event.target.currentTime;
      const caption = this.captions.find(c => currentTime >= c.time[0] && currentTime < c.time[1]);
      this.currentText = caption ? caption.text : '';
    },
      handleVideoPlay() {
        const video = this.$el.querySelector(".home_video");
        video.style.opacity = "1"; // Show video once it's ready
      },
      getLocation() {
        navigator.geolocation.getCurrentPosition((position) => {
          window.open(
            `/reservation-hotel?geo=${position.coords.latitude},${position.coords.longitude}`,
            "_blank"
          );
        });
      },
      animLogo(e) {
        e.currentTarget.animate(
          [
            { transform: "translateY(0px)" },
            { transform: "translateY(-10px)" },
            { transform: "translateY(0px)" },
          ],
          {
            duration: 800,
            easing: "ease-in-out",
            iterations: 2,
          }
        );
      },
    },
    components: { MainSearchMobile },
  };
  </script>
  
  <style lang="scss" scope='app/assets/stylesheets/components/banner-phone'>
    .banner-text--title em strong {
  font-weight: 700 !important;
  }
  #nav-phonee {
    width: 100vw;
    background: transparent;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;


    .nav-phone {
      width: 100%;
      height: 80px;
      max-height: 80px;
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .logo {
      img {
        width: 171px;
        height: 40px;
        margin-left: 1rem;
      }
    }

    .loupe {
      img {
        width: 42px;
        height: 31px;
      }
    }

    .profil {
      img {
        width: 23px;
        height: 30px;
      }
    }

  }
  </style>
  