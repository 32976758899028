<template>
    <div id="lodginghome-phone">
      <div class="phone-container">
        <div class="lodging-header">
          <!-- <h1 class="title-first merriweather-reg-24">Et si vos vacances de printemps devenaient bien plus qu’un simple séjour ?</h1> -->
        </div>
        <div id="myscroller" class="card-lodging-container">
              <div class="card-hub" onclick="window.open('https://www.hortense.green/hotel-de-charme', '_blank')">
                <div class="position-relative">
                  <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/hotels-vacances-ete.webp" class="prop-img" v-set-alt>
                </div>
              </div>
              <div class="card-hub" onclick="window.open('https://www.hortense.green/villa-de-charme', '_blank')">
                <div class="position-relative">
                  <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/villas-vacances-ete.webp"  class="prop-img" v-set-alt>
                </div>
              </div>
          <div class="card-hub" onclick="window.open('https://www.hortense.green/chambres-dhotes-de-charme', '_blank')">
            <div class="position-relative">
              <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/chambres-hotes-vacances-ete.webp" class="prop-img" v-set-alt>
            </div>
          </div>
              <div class="card-hub" onclick="window.open('https://www.hortense.green/logement-insolite-de-luxe', '_blank')">
                <div class="position-relative">
                  <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/lieux-insolites-vacances-ete.webp" class="prop-img" v-set-alt>
                </div>
              </div>
           </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: 'chambre-prepositions-phone',
      data() {
        return {
          options: {
            arrows: 0,
            rewind : true,
            width  : '100%',
            height: 304,
            padding: {
              right: '7rem',
            },
            perMove:1,
            gap    : '1rem',
            classes:{
              pagination: 'splide__pagination  pagi-phone', // container
              page : 'splide__pagination__page dot-phone', // each button
            }
          },
        };
      },
      methods: {
        autoScroll() {
          const scroller =document.getElementById('myscroller'); // Use a ref to get the container element
          let maxScrollLeft = scroller.scrollWidth - scroller.clientWidth;
  
          const step = 1; // Adjust step for speed
          const frame = () => {
            if (scroller.scrollLeft >= maxScrollLeft) {
              scroller.scrollLeft = 0; // Reset scroll to start when it reaches the end
            } else {
              scroller.scrollLeft += step;
            }
            requestAnimationFrame(frame);
          };
          frame();
        }
      },
      mounted() {
        this.autoScroll(); // Start automatic scrolling when the component is mounted
      }
    }
  </script>
  
  
  <style lang="scss" scope='app/assets/stylesheets/components/tips-phone'>
  .anchor{
          text-decoration: underline;
          font-family: $Raleway;
          font-weight: 500;
          font-size: 15px;
          color: #000000;
  }
  .prop-img {
  height: 270px !important;
  width: 295px !important;
}
  .element{
      //width: 120px;
      width: 100%;
  }
  </style>
  
  