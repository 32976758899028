<template>
  <div id="lodginghome-phone">
    <div class="phone-container">
      <div class="lodging-header">
        <h1 class="title-first merriweather-reg-24">Vivez l’été autrement avec Hortense</h1>
      </div>
      <div id="myscroller" class="card-lodging-container">
            <div class="card-hub">
              <div class="position-relative">
                <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances-ete-experience-exclusive.webp" class="prop-img" v-set-alt>
              </div>
              <div class="card-hub-info">
                <p class="element raleway-bold-p">Expérience exclusive</p>
                <p class="anchor">Accédez à des services négociés pour des moments privilégiés uniques.</p>
              </div>
            </div>
            <div class="card-hub">
              <div class="position-relative">
                <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances-ete-experience-locale.webp" class="prop-img" v-set-alt>
              </div>
              <div class="card-hub-info">
                <p class="element raleway-bold-p">Expérience locale</p>
                <p class="anchor">Explorez facilement la région grâce à nos guides et carnets d'adresses.</p>
              </div>
            </div>
        <div class="card-hub">
          <div class="position-relative">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances-ete-experience-bien-etre.webp" class="prop-img" v-set-alt>
          </div>
          <div class="card-hub-info">
            <p class="element raleway-bold-p">Expérience bien-être</p>
            <p class="anchor">Séjournez dans des hébergements choisis pour votre confort et sérénité.</p>
          </div>
        </div>
            <div class="card-hub">
              <div class="position-relative">
                <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances-printemps-experience-culinaire.webp" class="prop-img" v-set-alt>
              </div>
              <div class="card-hub-info">
                <p class="element raleway-bold-p">Expérience culinaire</p>
                <p class="anchor">Savourez des saveurs locales dans nos hébergements sélectionnés avec soin.</p>
              </div>
            </div>
         </div>
    </div>
  </div>
</template>

<script>

// import { Splide, SplideSlide } from '@splidejs/vue-splide';


  export default {
    name: 'summer-propositions-phone',
    // components:{ Splide, SplideSlide },
    data() {
      return {
        options: {
          arrows: 0,
          rewind : true,
          width  : '100%',
          height: 304,
          padding: {
            right: '7rem',
          },
          perMove:1,
          gap    : '1rem',
          classes:{
            pagination: 'splide__pagination  pagi-phone',
            page : 'splide__pagination__page dot-phone', 
          }
        },
      };
    },
    methods: {
      autoScroll() {
          const scroller = document.getElementById('myscroller');
          let maxScrollLeft = scroller.scrollWidth - scroller.clientWidth;
          let step = 0.000001;
          let scrollAmount = 0;
          
          function scroll() {
            if (scroller.scrollLeft >= maxScrollLeft) {
              scroller.scrollLeft = 0;
              scrollAmount = 0;
            } else {
              scroller.scrollLeft += step;
              scrollAmount += step;
            }
            
            setTimeout(() => {
              requestAnimationFrame(scroll);
            }, 800);
          }

          scroll();
        },
    },
    mounted() {
      this.autoScroll();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/tips-phone'>
.anchor{
  text-decoration: none !important;
        font-family: $Raleway;
        font-weight: 500;
        font-size: 15px;
        color: #000000;
}
.prop-img{
  height:194px;
  width: 260px;
}
.element{
    width: 100%;
}
</style>

