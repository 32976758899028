<template>
    <div id="banner">
        <video
          autoplay
          loop
          muted
          poster="../../images/vacances-printemps-d.jpg"
          class="home_video"
          @timeupdate="updateText"
        >
          <source src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/SPRING+BREAK+-+Site+-+ST.mov">
        </video>
      <div class="banner-container">
        <div class="banner-text">
        <h2 class="banner-text--title" v-if="currentText" v-html="currentText"></h2>
      </div>
        <div class="banner-search">
          <MainSearch></MainSearch>
        </div>
      </div>
      <div class="logo-see-more" @mouseover="animLogo" @click="scrollDown">
        <img src="../../images/fleche blanc.svg" v-set-alt>
      </div>
    </div>
  </template>
  
  
  <script>
  import MainSearch from "../btns/MainSearch.vue";
  
  export default {
    name: "spring-banner",
    components: {
      MainSearch,
    },
    data() {
      return {
        search: "",
        currentText: '',
        captions: [
          { time: [0, 3], text: "Ce <em><strong>printemps</strong></em>, tout change avec <em><strong>Hortense</strong></em>" },
          { time: [3, 6], text: "Le printemps vous invite à <em><strong>l'évasion</strong></em>" },
          { time: [6, 9], text: "Le printemps crée des <em><strong>souvenirs précieux</strong></em>" },
          { time: [9, 12], text: "Le printemps s'invite dans <em><strong>votre assiette</strong></em>" },
          { time: [12, 15], text: "Le printemps en toute <em><strong>exclusivité</strong></em>" },
          { time: [15, 18], text: "Le printemps prend <em><strong>soin de vous</strong></em>" },
          { time: [18, 21], text: "Le printemps vous guide vers des <em><strong>expériences uniques</strong></em>" },
          { time: [21, 24], text: "Avec Hortense, le printemps devient <em><strong>inoubliable</strong></em>" },
          { time: [24, 26], text: "Le printemps selon <em><strong>vos envies</strong></em>" },
          { time: [26, 30], text: "<em><strong>Réservez votre séjour printanier dès maintenant</strong></em>" },
        ]
      };
    },
    methods: {
      updateText(event) {
      const currentTime = event.target.currentTime;
      const caption = this.captions.find(c => currentTime >= c.time[0] && currentTime < c.time[1]);
      this.currentText = caption ? caption.text : '';
    },
      handleVideoPlay() {
        const video = this.$el.querySelector(".home_video");
        video.classList.add("fade-in-video"); // Add fade-in class when video starts
      },
      animLogo(e) {
        e.currentTarget.animate(
          [
            { transform: "translateY(0px)" },
            { transform: "translateY(-10px)" },
            { transform: "translateY(0px)" },
          ],
          {
            duration: 800,
            easing: "ease-in-out",
            iterations: 2,
          }
        );
      },
      scrollDown() {
        this.$el.nextElementSibling.scrollIntoView({ behavior: "smooth" });
      },
    },
  };
  </script>
  
  
  <style lang="scss" scope='app/assets/stylesheets/components/banner'>
  .home_video {
    max-width: 1920px;
    max-height: 1080px;
    width: 100%;
    object-fit: contain;
    height: auto;
    max-width: 1920px;
    position: absolute;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    background-size: cover;
    object-fit: cover;
    opacity: 1 !important; // Initially hidden
    transition: opacity 1s ease-in-out; // Smooth fade-in transition
  }
  
  .home_video.fade-in-video {
    opacity: 1; // Video becomes visible when ready
  }
  
  .banner-container {
    position: relative;
  }
  
  .banner-text {
    text-align: center;
  
    &--title {
      font-size: 40px;
      color: white;
    }
  
    &--para {
      font-size: 15px;
      color: white;
      text-transform: uppercase;
    }
  }
  
  .logo-see-more {
    position: absolute;
    bottom: 20px;
    cursor: pointer;
  }
  .banner-text--title em,
.banner-text--title strong {
    font-weight: 700 !important;
    font-style: italic !important;
    display: inline;
}

  </style>
  