<template>
    <div id="tips" :class="{'desktop-margin': !isPhone}">
      <div class="titles center tips-margin-top ">
        <h1 class="title-first merriweather-regg-40 mt-4">Et si vos vacances de printemps devenaient bien plus qu’un simple séjour ?</h1>
      </div>
      <div class="container-fluid py-2 mt-3">
      <div class="d-flex flex-row flex-nowrap row-weekend" id="scrollerr"  style="overflow: scroll; overflow-y: hidden">
          <div class="col-proposition col-weekend">
            <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
              <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances-printemps-experience-exclusive.webp" v-set-alt class="mb-2">
              <p class="element rraleway-bold-p mt-2 mb-4">Expérience exclusive</p>
              <p class="anchor">Accédez à des services négociés pour des moments privilégiés uniques.</p>
            </div>
          </div>
          <div class="col-proposition col-weekend">
            <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
              <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances-printemps-experience-bien-etre.webp" v-set-alt class="mb-2">
              <p class="element rraleway-bold-p mt-2 mb-4">Expérience bien-être</p>
              <p class="anchor">Séjournez dans des hébergements choisis pour votre confort et sérénité.</p>
            </div>
         </div>
         <div class="col-proposition col-weekend" >          
            <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
              <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances-printemps-experience-culinaire.webp" v-set-alt class="mb-2">
              <p class="element rraleway-bold-p mt-2 mb-4">Expérience culinaire</p>
              <p class="anchor">Savourez des saveurs locales dans nos hébergements sélectionnés avec soin.</p>
            </div>
          </div>
          <div class="col-proposition col-weekend" >          
            <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
              <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances-printemps-experience-locale.webp" v-set-alt class="mb-2">
              <p class="element rraleway-bold-p mt-2 mb-4">Expérience locale</p>
              <p class="anchor">Explorez facilement la région grâce à nos guides et carnets d'adresses.</p>
            </div>
          </div>
        </div>
        <!-- <div class="flex-shrink-0" style="position: absolute; margin-left: 41%;">
          <button @click="scroll_left" class="btn-left btn-link p-2 toggle text-dark"><img style="width: 12px; height: 24px;" src="../images/fleche gauche.svg" v-set-alt /></button>
        </div>
        <div class="flex-shrink-0" style="position: absolute; margin-left: 45%;">
          <button @click="scroll_right" class="btn-right btn-link toggle p-2 text-dark"><img style="width: 12px; height: 24px;" src="../images/fleche droite.svg" v-set-alt /></button>
        </div> -->
      </div>
      <div class="mt-5"></div>
    </div>
  </template>
  
  <script>
    import AOS from 'aos';
  
    export default {
      name: 'spring-propositions',
      data() {
        return {
          cssProps: {
            backgroundImage: `url(${require('../images/icotype.svg')})`
          }
        }
      },
      methods: {
        scroll_left() {
          let content = document.querySelector("#scrollerr");
          content.scrollLeft -= 220;
        },
        scroll_right() {
          let content = document.querySelector("#scrollerr");
          content.scrollLeft += 220;
        }
      },
      mounted() {
        AOS.init();
      },
      computed: {
        isPhone() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
          return true;
        }else{
          return false;
        }
       }
      },
    }
  </script>
  
  
  <style lang="scss" scope='app/assets/stylesheets/components/tips'>
  
  </style>
  <style lang="scss" scoped>
    .desktop-margin {
      margin-top: 1rem !important;
    }
    .center{
      text-align: center;
    }
    .anchor{
      text-decoration: none;
          font-family: $Raleway;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
    }
    .subtitle{
      width: 670px;
    }
    .element{
      width: 240px;
    }
    .card-proposition{
      width: 260px;
      img{
        width: 260px;
        object-fit: cover;
      }
    }
    .tips-margin-top {
      margin-top: 3rem;
    }
    .merriweather-regg-40 {
        font-family: "Merriweather";
        font-weight: 400;
        font-size: 40px;
     }
     .rraleway-bold-p{
      font-family: 'Raleway';
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;

     }
     #scrollerr {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-behavior: smooth;
    }

    .row-weekend {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }

    .col-weekend {
      flex: 0 0 auto;
    }

    .card-proposition {
      width: 260px;
      text-align: center;
    }

  </style>
  
  