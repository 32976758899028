<template>
  <div class="reservation-card" :class="{ 'w-100': isEntire }" v-if="item">
    <div v-if="!isEntire" class="room-name mt-5">
      <p class="raleway-reg-p24 mb-0">{{ name }}</p>
      <div class="capacity d-flex">
        <p class="raleway-reg-p24 mb-0 mr-2">{{ this.item.capacity }}</p>
        <img src="../../images/profil.svg" v-set-alt />
      </div>
    </div>
    <div class="reservation-flex">
      <div v-if="!isEntire && !isPhone" class="hub-img-zoom">
        <img :src="image" v-set-alt class="hub-img" />
        <ModalRoomGalery
          :item="this.item"
          v-if="modalGallery"
          @close="modalGallery = false"
        ></ModalRoomGalery>
        <div class="btn-galerie">
          <div class="galerie-icon df-jsb-aic">
            <img src="../../images/Galerie icone.svg" v-set-alt />
            <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
          </div>
        </div>
      </div>

      <div  v-if="!isEntire && isPhone">
        <div class="slider-container">
          <button class="arrow left-arrow" @click="prevSlide">
            <img src="../../images/fleche gauche.svg" v-set-alt class="arrow-image" />
          </button>

          <div class="slider">
            <img
              :src="getPhotoUrl(currentIndex)"
              v-set-alt
              class="slider-image"
            />
            <div class="image-counter">{{ currentIndex + 1 }}/{{ totalPhotos }}</div>
          </div>

          <button class="arrow right-arrow" @click="nextSlide">
            <img src="../../images/fleche droite.svg" v-set-alt class="arrow-image" />
          </button>
        </div>
      </div>

      <div class="reservation-description">
        <div v-if="!isEntire && isPhone" class="info-container grid d-flex">
          <div class="center">
            <p><strong>Chambre</strong> {{ item.room_type }}</p>
            <p><strong>Lit</strong> {{ item.bed_type }}</p>
            <p><strong>Superficie</strong> {{ item.area }}m²</p>
            <p><strong>Vue</strong> {{ item.view_on }}</p>
            <p v-if="!isEntire"><strong>Capacité</strong> {{ formatCapacity(item.capacity) }}</p>
          </div>
          <div class="center">
            <p class="raleway-bold-p15">Équipements</p>
            <p v-for="service in item.service_rooms.slice(0, this.value)" :key="service.id">
              {{ service.name }}
            </p>
          </div>
        </div>
        <div v-if="this.item.service_rooms && !isEntire && isPhone">
          <div v-if="this.value >= this.item.service_rooms.length" class="pagination-hub d-flex mt-3">
            <a @click="SeeLessService" class="link-hortense">
              voir moins
              <img src="../../images/Fleche haut.svg" v-set-alt />
            </a>
          </div>
          <div v-else class="pagination-hub d-flex mt-3">
            <a @click="SeeMoreService" class="link-hortense">
              voir plus
              <img src="../../images/flechebas-simple.svg" v-set-alt />
            </a>
          </div>
        </div>
      <div v-if="!isEntire && !isPhone" class="grid">
          <div class="center">
            <p><strong>Chambre</strong> {{ item.room_type }}</p>
            <p><strong>Lit</strong> {{ item.bed_type }}</p>
            <p><strong>Superficie</strong> {{ item.area }}m²</p>
            <p><strong>Vue</strong> {{ item.view_on }}</p>
            <p v-if="!isEntire"><strong>Capacité</strong> {{ formatCapacity(item.capacity) }}</p>
          </div>
          <div class="center">
            <p class="raleway-bold-p15">Équipements</p>
            <p
              v-for="service in item.service_rooms.slice(0, this.value)"
              :key="service.id"
            >
              {{ service.name }}
            </p>
          </div>
        </div>
        <div v-if="this.item.service_rooms && !isEntire && !isPhone">
          <div v-if="this.value >= this.item.service_rooms.length" class="pagination-hub d-flex mt-3">
            <a @click="SeeLessService" class="link-hortense">
              voir moins <img src="../../images/Fleche haut.svg" v-set-alt
            /></a>
          </div>
          <div v-else class="pagination-hub d-flex mt-3">
            <a @click="SeeMoreService" class="link-hortense">
              voir plus
              <img src="../../images/flechebas-simple.svg" v-set-alt
            /></a>
          </div>
       </div>


      <!--KOEDIA-->
      <!--<div v-if="this.accomodationCode && !item.is_entire">-->
      <div v-if="this.accomodationCode">
        <!--<div v-if="!this.roomAvailability || this.roomAvailability.options.length === 0 || isSearching" class="reservation-box">-->
        <div v-if="!this.priceOptions || this.priceOptions.length === 0 || isSearching" class="reservation-box"   :class="{ 'full-width': bookButtonCaption === 'Indisponible' || bookButtonCaptionNotKoedia === 'Indisponible', 'normal-width': bookButtonCaption !== 'Indisponible' && bookButtonCaptionNotKoedia !== 'Indisponible' }">
          <div class="box-price box-price-no-search">
            <div class="box-middle d-flex">
              <button
                :disabled="disableButton"
                class="hortense-btn-primary"
                @click="$emit('expandCalendar');"
              >
                {{ bookButtonCaption }}
              </button>
            </div>
          </div>
          <div v-if="(!this.roomAvailability || this.roomAvailability.options.length === 0) && this.searched && minNights > nights">
            <p class="raleway-reg-p15"><i>Nombre de nuits minimum : {{ minNights }}.</i></p>
            <p class="raleway-reg-p15">Veuillez refaire votre recherche de disponibilité.</p>
          </div>
        </div>

        <div class="reservation-container" ref="reservationContainer" v-if="this.accomodationCode">
          <div v-for="(priceOption, idx) in priceOptions" :key="idx" class="reservation-box"   :class="{ 'full-width': bookButtonCaption === 'Indisponible' || bookButtonCaptionNotKoedia === 'Indisponible', 'normal-width': bookButtonCaption !== 'Indisponible' && bookButtonCaptionNotKoedia !== 'Indisponible' }">
            <!-- <div v-if="!isPhone" class="box-price" :class="{ 'box-price-two-col': isEntire }">
              <div class="delice-section">
                  <div class="py-4 px-3 d-flex h-100 align-items-center mobile-bullet">
                    <div class="d-flex align-items-start check">
                      <div>
                        <h4 class="_sub_title_right p-1">{{boardType(idx) == "Chambre seulement" ? 'DOUCE NUIT' : boardType(idx) == "Petit-déjeuner" || boardType(idx) == "Petit déjeuner inclus" ? 'DÉLICE AU RÉVEIL' : boardType(idx) == "Demi-pension" || boardType(idx) == "Demi pension inclus" || boardType(idx) == "Demi-pension inclus" ? 'ESCAPADE GOURMANDE' : boardType(idx) == "Tout inclus" || boardType(idx) == "Tout-inclus" ? 'PLAISIRS ILLIMITÉS' : boardType(idx) == "complète-pension" || boardType(idx) == "complète pension inclus" || boardType(idx) == "complète-pension inclus" || boardType(idx) == "complet-pension" || boardType(idx) == "complet pension inclus" || boardType(idx) == "complet-pension inclus" ? 'ÉVASION CULINAIRE'  : 'DOUCE NUIT'}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              <div class="box-middle-two">
                <p class="raleway-semi-p15">{{ cancellationMessages[idx] }}</p>
                <p class="raleway-semi-p15">{{ boardType(idx) }}</p>
              </div>
              <div class="box-middle">
                <div v-if="roomAvailability" class="mobile-gridd">
                  <p class="raleway-reg-p15">{{ pricePerNightTtc(idx) }}€ par nuit</p>
                  <div class="taxes">
                    <p class="raleway-semi-p13 mb-0">{{ totalTtc(idx) }}€ au total</p>
                    <p class="raleway-reg-p13">Taxes et frais compris</p>
                  </div>
                </div>
              </div>
              <div class="box-middle d-flex" :class="{ 'box-two-col': isEntire }">
                <button :disabled="disableButton" class="hortense-btn-primary" @click="sendInfo(idx)">
                  {{ bookButtonCaption }}
                </button>
              </div>
            </div> -->
            <div class="box-price">
              <div class="delice-section">
                  <div class="py-4 px-3 d-flex h-100 align-items-center mobile-bullet">
                    <div class="d-flex align-items-start check">
                      <div>
                        <h4 class="_sub_title_right p-1">{{boardType(idx) == "Chambre seulement" ? 'DOUCE NUIT' : boardType(idx) == "Petit-déjeuner" || boardType(idx) == "Petit déjeuner inclus" ? 'DÉLICE AU RÉVEIL' : boardType(idx) == "Demi-pension" || boardType(idx) == "Demi pension inclus" || boardType(idx) == "Demi-pension inclus" ? 'ESCAPADE GOURMANDE' : boardType(idx) == "Tout inclus" || boardType(idx) == "Tout-inclus" ? 'PLAISIRS ILLIMITÉS' : boardType(idx) == "complète-pension" || boardType(idx) == "complète pension inclus" || boardType(idx) == "complète-pension inclus" || boardType(idx) == "complet-pension" || boardType(idx) == "complet pension inclus" || boardType(idx) == "complet-pension inclus" ? 'ÉVASION CULINAIRE'  : 'DOUCE NUIT'}}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                    <div class="conditions">
                      <p class="raleway-semi-p15" style="text-align: left; color: #000;">
                        {{ cancellationMessages[idx] }}
                      </p>
                      <p class="raleway-semi-p15" style="text-align: left; color: #000;">
                        {{ boardType(idx) }}
                      </p>
                    </div>
                    <div class="pricing-section">
                    <div class="price-row totall">
                      <p class="raleway-semi-p15">Tarif par nuit</p>
                      <p class="raleway-semi-p13" style="color: black;">{{ pricePerNightTtc(idx) }}€</p>
                    </div>

                    <div class="price-row total">
                      <div class="total-content">
                        <p class="raleway-semi-p15">TOTAL</p>
                        <p class="raleway-semi-p13" style="color: black;">{{ totalTtc(idx) }}€</p>
                      </div>
                      <p class="price-note" style="text-align: left;">Taxes et frais compris</p>
                    </div>
                  </div>
                    <div class="button-container">
                      <button :disabled="disableButton" class="hortense-btn-primary" @click="sendInfo(idx)">
                        {{ bookButtonCaption }}
                      </button>
                    </div>
                  </div>
            <div v-if="isPhone" class="mobile-member-section">
            <button
              :class="['member-btn', { active: showMenu }]"
              @click="toggleMenu"
            >
              RÉSERVÉ AUX MEMBRES
            </button>
            <div v-if="showMenu" class="member-menu">
              <h2>POURQUOI NE PAS DEVENIR MEMBRE ?</h2>
              <p>
                Avec le programme de fidélité Hortensia, accédez à des services
                exclusifs pendant votre séjour. Avec Hortense, transformez votre nuit
                d'hôtel en une expérience unique et voyagez en tant qu'invité
                privilégié.
              </p>
              <ul class="custom-tick-list" v-if="lodging.green_trotters && lodging.green_trotters.length">
                <template v-for="gift in lodging.green_trotters[3].gifts">
                  <li>{{ gift.name }}</li>
                </template>
              </ul>
              <button class="btn-member" @click="$emit('open-modal');">DEVENIR MEMBRE</button>
              <p class="already-member">
                Déjà membre ? <a href="javascript:void(0)" @click.prevent="$emit('open-login-modal')">Connectez-vous</a>
              </p>
              <p class="note">
                Les services exclusifs auxquels vous pouvez accéder varient en fonction de
                vos points de fidélité et de votre statut au sein de notre programme de
                fidélité. Cette information est disponible dans votre espace client.
              </p>
            </div>
          </div>
          </div>
        </div>
      </div>
      
      <!-- NOT KOEDIA -->
      <div v-if="!this.accomodationCode">
        <div v-if="!this.roomAvailabilityNotKoedia || this.roomAvailabilityNotKoedia.length === 0 || isSearching" class="reservation-box" style="width: 100% !important;">
          <div class="box-price box-price-no-search">
            <div class="box-middle d-flex">
              <button
                :disabled="disableButtonNotKoedia"
                class="hortense-btn-primary"
                @click="$emit('expandCalendar');"
              >
                {{ bookButtonCaptionNotKoedia }}
              </button>
            </div>
          </div>
        </div>
        <div v-else class="reservation-container" ref="reservationContainer">
        <div  v-for="tariff in this.tariffsNotKoedia" :key="tariff" class="reservation-box">
              <!-- <div v-if="pricePerNightTtcNotKoedia(tariff) && !isPhone" class="box-price" :class="{ 'box-price-two-col': isEntire }">
                <div v-if="getOptionFromHash(tariff)" class="delice-section">
                  <div class="py-4 px-3 d-flex h-100 align-items-center mobile-bullet">
                    <div class="d-flex align-items-start check">
                      <div>
                        <h4 class="_sub_title_right p-1">{{ getOptionFromHash(tariff) }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                  <div class="box-middle-three">
                      <p class="raleway-semi-p15">{{ cancellationMessages[idx] }}</p>
                      <p v-for="idx in tariff.split(',')" class="raleway-semi-p15">{{ optionHash[idx] }}</p>
                  </div>
                <div class="box-middle"> -->
                    <!--<div v-if="this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length != 0">-->
                      <!-- <p class="raleway-reg-p15">{{ computedTariffPrices[tariff] }}€ par nuit</p>
                     <div class="taxes">
                        <p class="raleway-semi-p13 mb-0">{{ totalTtcNotKoedia(tariff) }}€ au total</p>
                        <p class="raleway-reg-p13">Taxes et frais compris</p>
                     </div>
                </div>
                <div class="box-middle d-flex" :class="{ 'box-two-col': isEntire }">
                  <button :disabled="disableButtonNotKoedia" class="hortense-btn-primary" @click="sendInfo(tariff)">
                    {{ bookButtonCaptionNotKoedia }}
                  </button>
                </div>
              </div> -->
              <div v-if="pricePerNightTtcNotKoedia(tariff)" class="box-price">
                  <div v-if="getOptionFromHash(tariff)" class="delice-section">
                    <div class="py-4 px-3 d-flex h-100 align-items-center mobile-bullet">
                      <div class="d-flex align-items-start check">
                        <div>
                          <h4 class="_sub_title_right p-1">{{ getOptionFromHash(tariff) }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="conditions">
                      <p v-for="(message, idx) in cancellationMessages" :key="idx" class="raleway-semi-p15" style="text-align: left; color: #000;">
                        {{ message }}
                      </p>
                      <p v-for="idx in tariff.split(',')" :key="idx" class="raleway-semi-p15" style="text-align: left; color: #000;">
                        {{ optionHash[idx] }}
                      </p>
                    </div>
                    <div class="pricing-section">
                      <div class="price-row totall">
                        <p class="raleway-semi-p15">Tarif par nuit</p>
                        <p class="raleway-semi-p15" style="color: black;">{{ computedTariffPrices[tariff] }}€</p>
                      </div>

                      <div class="price-row total">
                        <div class="total-content">
                          <p class="raleway-semi-p15">TOTAL</p>
                          <p class="raleway-semi-p15" style="color: black;">{{ totalTtcNotKoedia(tariff) }}€</p>
                        </div>
                        <p class="price-note" style="text-align: left;">Taxes et frais compris</p>
                      </div>
                    </div>
                    <div class="button-container">
                      <button :disabled="disableButtonNotKoedia" class="hortense-btn-primary" @click="sendInfo(tariff)">
                        {{ bookButtonCaptionNotKoedia }}
                      </button>
                    </div>
                  </div>
              <div v-if="isPhone" class="mobile-member-section">
                <button
                  :class="['member-btn', { active: showMenu }]"
                  @click="toggleMenu"
                >
                  RÉSERVÉ AUX MEMBRES
                </button>
                <div v-if="showMenu" class="member-menu">
                  <h2>POURQUOI NE PAS DEVENIR MEMBRE ?</h2>
                  <p>
                    Avec le programme de fidélité Hortensia, accédez à des services
                    exclusifs pendant votre séjour. Avec Hortense, transformez votre nuit
                    d'hôtel en une expérience unique et voyagez en tant qu'invité
                    privilégié.
                  </p>
                  <ul class="custom-tick-list" v-if="lodging.green_trotters && lodging.green_trotters.length">
                    <template v-for="gift in lodging.green_trotters[3].gifts">
                      <li>{{ gift.name }}</li>
                    </template>
                  </ul>
                  <button class="btn-member" @click="$emit('open-modal');">DEVENIR MEMBRE</button>
                  <p class="already-member">
                    Déjà membre ? <a href="javascript:void(0)" @click.prevent="$emit('open-login-modal')">Connectez-vous</a>
                  </p>
                  <p class="note">
                    Les services exclusifs auxquels vous pouvez accéder varient en fonction de
                    vos points de fidélité et de votre statut au sein de notre programme de
                    fidélité. Cette information est disponible dans votre espace client.
                  </p>
                </div>
              </div>
        </div>
      </div>
      <div v-if="tariffsNotKoedia && tariffsNotKoedia.length > 1" class="bullet-indicators">
          <span
            v-for="(item, idx) in tariffsNotKoedia"
            :key="idx"
            class="bullet"
            :class="{ active: idx === activeBulletIndex }"
            @click="setActiveBullet(idx)"
          ></span>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueImageSlider from "vue-image-slider";
import ModalRoomGalery from "../../components/modals/ModalRoomGalery.vue";
import fetcher from "packs/shared/api.js";

export default {
  name: "ReservationCard",
  components: { VueImageSlider, ModalRoomGalery },
  props: {
    minNights: Number,
    lodging: Object,
    koediaAvailability: Boolean,
    item: Object,
    searched: Boolean,
    isSearching: Boolean,
    nights: Number,
    from: Date,
    to: Date,
    roomAvailability: Object,
    roomAvailabilityNotKoedia: [],
    numberOfRoom: {
      default: 0,
      type: Number,
    },
    isEntire: Boolean,
    taxNotKoedia: Object,
    accomodationCode: Object,
    koediaFrom: String,
    koediaTo: String,
    tariffsNotKoedia: [],
    koediaSessionId: String,
  },
  data() {
    return {
      activeBulletIndex: 0,
      showMenu: false,
      currentIndex: 0,
      baseUrl: 'https://www.hortense.green',
      tvaNotKoedia: Number,
      houseHoldNotKoedia: String,
      checkIn: null,
      checkOut: null,
      // imageArray: this.item.photos_keys,
      modalGallery: false,
      value: 3,
      subTotal: 0,
      gettingRateDetails: true,
      rateDetails: null,
      roomChoosedCount: 0,
      price: null,
      status: {
        getRateDetails: null,
      },
      cancellationMessages: {},
      image: `${
        this.item.photo_banner ??
        "https://images.unsplash.com/flagged/photo-1556438758-8d49568ce18e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80"
      }`,
       pricingHash: {
        pn: 'breakfast_notinclude_pricing',
        pd: 'breakfast_include_pricing',
        cf: 'cancellation_free_pricing',
        nr: 'non_refundable_pricing',
        ai: 'all_inclusive_pricing',
        hp: 'half_pension_pricing',
        fp: 'full_pension_pricing',
        cc: 'cancel_condition_pricing'
      },
      optionHash: {
        pn: 'Petit déjeuner non inclus',
        pd: 'Petit déjeuner inclus',
        cf: 'Annulation gratuite',
        nr: 'Non remboursable',
        ai: 'Tout inclus',
        hp: 'Demi-pension',
        fp: 'Pension complète',
        cc: 'Annulation sous conditions',
        cs: 'Chambre seulement',
        t1: 'Tarif pour 1 personne',
        t2: 'Tarif pour 2 personnes',
        t3: 'Tarif pour 3 personnes',
        t4: 'Tarif pour 4 personnes',
        t5: 'Tarif pour 5 personnes',
        t6: 'Tarif pour 6 personnes',
        t7: 'Tarif pour 7 personnes',
        t8: 'Tarif pour 8 personnes',
        t9: 'Tarif pour 9 personnes',
        t10: 'Tarif pour 10 personnes',
        n7: '7 nuits minimum',
        n6: '6 nuits minimum',
        n5: '5 nuits minimum',
        n4: '4 nuits minimum',
        n3: '3 nuits minimum',
        n2: '2 nuits minimum'
      },
      optionHashTwo: {
        pn: 'DOUCE NUIT',
        pd: 'DÉLICE AU RÉVEIL',
        ai: 'PLAISIRS ILLIMITÉS',
        hp: 'ESCAPADE GOURMANDE',
        fp: 'ÉVASION CULINAIRE',
        cs: 'DOUCE NUIT',
        nr: 'DOUCE NUIT',
        // cc: 'DÉLICE AU RÉVEIL'
      },
      //image: { backgroundImage: `url("${this.item.photo_banner ?? "https://images.unsplash.com/flagged/photo-1556438758-8d49568ce18e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80" }")`}
    };
  },
  mounted(){
    console.log(this.item)
    console.log('tariffsNotKoedia');
    console.log(this.tariffsNotKoedia);
    this.equalizeCardHeights();
  window.addEventListener('resize', this.equalizeCardHeights);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.equalizeCardHeights);
  },
  watch: {
    from: {
      immediate: true,
      handler(val) {
        this.checkIn = val;
      },
    },
    to: {
      immediate: true,
      handler(val) {
        this.checkOut = val;
      },
    },
    numberOfRoom: {
      immediate: true,
      handler(val) {
        this.roomChoosedCount = val;
      },
    },
    priceOptions: {
      handler(newOptions) {
        if(newOptions) {
          newOptions.forEach((option, idx) => {
          this.isRefundable(idx);
        });
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isPhone() {
    console.log(this.tariffsNotKoedia);
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {

					return true;
				}else{
					return false;
				}
		},
    totalPhotos() {
      return this.item.photos_keys ? this.item.photos_keys.length : 0;
    },
    bookButtonCaption() {
      if (this.isSearching) {
        return "Recherche en cours...";
      } else if (!this.searched) {
        return "Disponibilité à confirmer";
      //} else if (this.isAvailable) {
      //  return "Réserver";
      //} else {
      //  return "Indisponible";
      //}
	  } else if (this.roomAvailability && this.roomAvailability.options.length != 0) {
        return "Réserver";
      } else if (!this.roomAvailability || this.roomAvailability.options.length === 0){
        return "Indisponible";
      }
	  
    },
    bookButtonCaptionNotKoedia() {
      if (this.isSearching) {
        return "Recherche en cours...";
      } else if (!this.searched) {
        return "Disponibilité à confirmer";
      } else if (this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length != 0) {
        return "Réserver";
      } else if (!this.roomAvailabilityNotKoedia || this.roomAvailabilityNotKoedia.length === 0){
        return "Indisponible";
      }
    },
    name() {
      return this.item.name;
    },
    total() {
      if (this.checkIn && this.checkOut && this.item.availabilities) {
        const dateCheckIn = this.item.availabilities.find(
          (el) => el.date === this.formatDate(this.checkIn)
        );
        const indexFrom = this.item.availabilities.indexOf(dateCheckIn);
        const dateCheckOut = this.item.availabilities.find(
          (el) => el.date === this.formatDate(this.checkOut)
        );
        const indexTo = this.item.availabilities.indexOf(dateCheckOut);
        const sum = this.item.availabilities
          .slice(indexFrom, indexTo + 1)
          .reduce((acc, current) => acc + current.pricing, 0);
        return sum * this.numberOfRoom;
      }
    },
    isAvailable() {
      return this.roomAvailability;
    },
    disableButton() {
      return (this.searched && (!this.isAvailable || this.roomAvailability.options.length === 0)) || this.isSearching;
    },
    disableButtonNotKoedia() {
      return (this.searched && (!this.roomAvailabilityNotKoedia || this.roomAvailabilityNotKoedia.length === 0)) || this.isSearching;
    },
    priceOptions() {
      if (this.isAvailable) {
        console.log('DEBUG priceOptions');
        console.log(this.roomAvailability.options);

        const uniqueOptions = this.roomAvailability.options.reduce((acc, current) => {
          if (!acc.some(option => option.room_id === current.room_id)) {
            acc.push(current);
          }
          return acc;
        }, []);

        return uniqueOptions;
      }
    },
    computedTariffPrices() {
    if (this.roomAvailabilityNotKoedia && this.tariffsNotKoedia && this.tariffsNotKoedia.length) {
      return this.tariffsNotKoedia.reduce((acc, tariff) => {
        const pricing_unitt = this.roomAvailabilityNotKoedia.find((el) => el.tariff === tariff);
        if (pricing_unitt) {
          const pricing_unit = pricing_unitt.tariff_price;
          const tva_rate = this.taxNotKoedia?.tva_rate ?? 0;
          const tourist_tax = this.taxNotKoedia?.tourist_tax ?? 0;
          const tvaNotKoedia = pricing_unit * (tva_rate / 100);
          console.log('tvaNotKoedia', tvaNotKoedia)
          acc[tariff] = (pricing_unit + tvaNotKoedia + tourist_tax).toFixed(2);
        }
        return acc;
      }, {});
    }
    return {};
  }
  },
  methods: {
    setActiveBullet(index) {
    this.activeBulletIndex = index;
    const reservationContainer = this.$refs.reservationContainer;
    const card = reservationContainer.children[index];
    if (card) {
      card.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center"
      });
    }
  },
    equalizeCardHeights() {
    this.$nextTick(() => {
      const container = this.$refs.reservationContainer;
      if (container) {
        const boxes = container.querySelectorAll('.reservation-box');
        let maxHeight = 0;

        // Reset heights to auto to recalculate properly
        boxes.forEach((box) => {
          box.style.height = 'auto';
        });

        // Calculate the maximum height
        boxes.forEach((box) => {
          maxHeight = Math.max(maxHeight, box.offsetHeight);
        });

        // Apply the maximum height to all cards
        boxes.forEach((box) => {
          box.style.height = `${maxHeight}px`;
        });
      }
    });
  },
    getOptionFromHash(tariff) {
    const keys = tariff.split(',');
    if (this.optionHashTwo[keys[0]]) {
      return this.optionHashTwo[keys[0]];
    }
    if (keys[1] && this.optionHashTwo[keys[1]]) {
      return this.optionHashTwo[keys[1]];
    }
    if (keys[2] && this.optionHashTwo[keys[2]]) {
      return this.optionHashTwo[keys[2]];
    }
    return null;
  },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    getPhotoUrl(index) {
      console.log('!this.item.photos_keys', this.item.photos_keys)
      if(!this.item.photos_keys || this.item.photos_keys.length == 0) {
        return this.item.photo_banner ??
        "https://images.unsplash.com/flagged/photo-1556438758-8d49568ce18e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80"
      }
      return this.item.photos_keys[index];
    },
    nextSlide() {
      if (this.currentIndex < this.totalPhotos - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.totalPhotos - 1;
      }
    },
    displayCalendar() {
      // ???
    },
    pricePerNightTtc(idx) {
      if (this.priceOptions) {
        return this.priceOptions[idx].room_price.per_day.rack;
      }
    },
    totalTtc(idx) {
      if (this.priceOptions) {
        return this.priceOptions[idx].room_price.total.rack.toFixed(2);
      }
    },
    async getRateDetails(idx) {
      if (this.accomodationCode) {
        this.gettingRateDetails = true;
        try {
          const res = await fetcher.post(`/lodgings/${this.lodging.slug}/get_rate_details`, {
            koedia_session_id: this.koediaSessionId,
            koedia_room_id: this.priceOptions[idx].room_id,
            start: this.koediaFrom,
            end: this.koediaTo,
            children: 0,
            children_ages: [],
          });
          console.log('res.data', res.data)
          console.log('res.data', res.data.get_rate_details_response)
          this.rateDetails = res.data.get_rate_details_response.res_get_rate_details;
          if (this.priceOptions[idx].refundable) {
            const refundableToDay = Array.isArray(this.rateDetails.cancellation_fees_policy)
                ? this.rateDetails.cancellation_fees_policy[0].to_day
                : this.rateDetails.cancellation_fees_policy.to_day;

            const koediaFromDate = new Date(this.koediaFrom);
            koediaFromDate.setDate(koediaFromDate.getDate() - refundableToDay);

            const resultYear = koediaFromDate.getFullYear();
            const resultMonth = (koediaFromDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
            const resultDay = koediaFromDate.getDate().toString().padStart(2, '0');

            const resultDate = `${resultDay}-${resultMonth}-${resultYear}`;

            console.log('Resulting date after subtracting refundableToDay:', resultDate);

            return resultDate;
          }
        } catch (e) {
          this.status.getRateDetails = false;
          console.log("Failed getRateDetails");
          console.log(e);
          throw e; // Re-throw the error to handle it in isRefundable
        }
      }
    },
    pricePerNightTtcNotKoedia(tariff) {

  if (this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length !== 0) {
    console.log('this.roomAvailabilityNotKoedia', this.roomAvailabilityNotKoedia);
    console.log('tariff', tariff);

    const pricing_unitt = this.roomAvailabilityNotKoedia.find((el) => el.tariff === tariff);
    if (!pricing_unitt) {
      console.error(`No matching tariff found for: ${tariff}`);
      return null;
    }

    const pricing_unit = pricing_unitt.tariff_price;

    const tva_rate = this.taxNotKoedia?.tva_rate ?? 0;
    const tourist_tax = this.taxNotKoedia?.tourist_tax ?? 0;

    // Avoid mutating reactive properties
    const tvaNotKoedia = pricing_unit * (tva_rate / 100);
    console.log('tvaNotKoedia', tvaNotKoedia)

    return (pricing_unit + tvaNotKoedia + tourist_tax).toFixed(2);
  }

  return null;
},
priceTvaNotKoedia(tariff) {

if (this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length !== 0) {

  const pricing_unitt = this.roomAvailabilityNotKoedia.find((el) => el.tariff === tariff);
  if (!pricing_unitt) {
    console.error(`No matching tariff found for: ${tariff}`);
    return null;
  }

  const pricing_unit = pricing_unitt.tariff_price;

  const tva_rate = this.taxNotKoedia?.tva_rate ?? 0;
  const tourist_tax = this.taxNotKoedia?.tourist_tax ?? 0;

  // Avoid mutating reactive properties
  const tvaNotKoedia = pricing_unit * (tva_rate / 100);
  console.log('tvaNotKoedia', tvaNotKoedia)

  return tvaNotKoedia;
}

return null;
},
     priceHtNotKoedia(tariff) {
      if(this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length != 0)
      {
          return this.roomAvailabilityNotKoedia.find((el) => el.tariff === tariff).tariff_price;
      }
      return null;
    },
    totalTtcNotKoedia(tariff) {
  console.log('tariff', tariff);
  console.log('this.roomAvailabilityNotKoedia', this.roomAvailabilityNotKoedia);

  if (this.roomAvailabilityNotKoedia && this.roomAvailabilityNotKoedia.length !== 0) {
    const pricing_unit = this.roomAvailabilityNotKoedia.find((el) => el.tariff === tariff).tariff_price;
    console.log('UNIT PRICING');
    console.log(pricing_unit);

    const nights = (this.to - this.from) / 864e5 || 1; // Ensure at least 1 night

    const tva_rate = this.taxNotKoedia?.tva_rate ?? 0;
    const tourist_tax = this.taxNotKoedia?.tourist_tax ?? 0;

    const total = (pricing_unit + tourist_tax + pricing_unit * (tva_rate / 100)) * nights;
    console.log('totaltotaltotaltotaltotaltotal', total)
    return total.toFixed(2);
  }

  return null;
},
    async isRefundable(idx) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString('fr-FR');
      if (this.priceOptions) {
        try {
          const cancel = await this.getRateDetails(idx);
          this.$set(this.cancellationMessages, idx, this.priceOptions[idx].refundable ? `Annulation gratuite avant le ${cancel}` : "Non annulable");
        } catch (e) {
          console.log('Failed to get rate details:', e);
          this.$set(this.cancellationMessages, idx, "");
        }
      }
    },
    boardType(idx) {
      console.log('this.priceOptionsthis.priceOptions', this.priceOptions)
      if (this.priceOptions) {
        return Object.values(this.priceOptions[idx].board)[0];
      }
    },
    personDetails(idx) {
      if (this.item) {
        const children = parseInt(this.priceOptions[idx].children);
        const adults = parseInt(this.priceOptions[idx].adults);
        const totalPeople = children + adults;
        const personneSuffix = totalPeople > 1 ? "personnes" : "personne";
        return `Tarif pour ${totalPeople} ${personneSuffix}`;
      }
    },
    personsAmount(idx) {
      if (this.item) {
        const children = parseInt(this.priceOptions[idx].children);
        const adults = parseInt(this.priceOptions[idx].adults);
        const childrenAmount = children > 1 ? this.priceOptions[idx].children + " Enfants" : this.priceOptions[idx].children +  " Enfant";
        const adultAmount = adults > 1 ? this.priceOptions[idx].adults + " Adultes" : this.priceOptions[idx].adults +  " Adultes";
        return `(${adultAmount}, ${childrenAmount})`;
      }
    },
    SeeMoreService(e) {
      e.preventDefault();
      this.value += 3;
    },
    SeeLessService(e) {
      e.preventDefault();
      this.value = 3;
    },
    showModalGallery() {
      this.modalGallery = !this.modalGallery;
    },

    formatCapacity(data) {
      if (data.length >= 1) {
        return `${data} personne`;
      } else {
        return `${data} personnes`;
      }
    },
    seeMore() {
      console.log(this.item);
    },
    // sendInfo() {
    //   this.$emit("sendInfo", this.item, this.numberOfRoom, this.total);
    // },
    sendInfo(idx) {
      window.scrollTo({ top: 0, left: 0 });

      if(this.accomodationCode)
      {
          this.$emit(
            "sendInfo",
            this.item,
            this.pricePerNightTtc(idx),
            this.totalTtc(idx),
            this.priceOptions[idx],
            idx
          );
          window.location.href = '/reservation-hotel/' + this.lodging.slug + '/reservation/confirm'
      }
      else
      {
        console.log('this.tvaNotKoediathis.tvaNotKoedia', this.tvaNotKoedia)
        console.log("this.item:", this.item);
        console.log("this.pricePerNightTtcNotKoedia(idx):", this.pricePerNightTtcNotKoedia(idx));
        console.log("this.totalTtcNotKoedia(idx):", this.totalTtcNotKoedia(idx));
        console.log("this.roomAvailabilityNotKoedia:", this.roomAvailabilityNotKoedia);
        console.log("idx:", idx);
        console.log("this.priceTvaNotKoedia(idx):", this.priceTvaNotKoedia(idx));
        console.log("this.priceHtNotKoedia(idx):", this.priceHtNotKoedia(idx));
        console.log("this.houseHoldNotKoedia:", this.houseHoldNotKoedia);
          this.$emit(
            "sendInfo",
            this.item,
            this.pricePerNightTtcNotKoedia(idx),
            this.totalTtcNotKoedia(idx),
            this.roomAvailabilityNotKoedia,
            idx,
            this.priceTvaNotKoedia(idx),
            this.priceHtNotKoedia(idx),
            this.houseHoldNotKoedia
          );
        window.location.href = '/reservation-hotel/' + this.lodging.slug + '/reservation/confirm'
      }
    },
    formatDate(date) {
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      let year = date.getFullYear();

      return year + "-" + month + "-" + day;
    },
    findPrice() {
      if (this.checkIn && this.checkOut && this.item.availabilities) {
        const dateCheckIn = this.item.availabilities.find(
          (el) => el.date === this.formatDate(this.checkIn)
        );
        const indexFrom = this.item.availabilities.indexOf(dateCheckIn);
        const dateCheckOut = this.item.availabilities.find(
          (el) => el.date === this.formatDate(this.checkOut)
        );
        const indexTo = this.item.availabilities.indexOf(dateCheckOut);

        const sum = this.item.availabilities
          .slice(indexFrom, indexTo + 1)
          .reduce((acc, current) => acc + current.pricing, 0);
        this.price = (sum / (indexTo - indexFrom + 1)).toFixed(2);
      }
      if (this.price) return this.price;
      else return this.item.pricing;
    },
  }
};
</script>

<style lang="scss" scoped>
.info-container.grid {
  display: grid;
  grid-template-columns: 1fr;
}

.reservation-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    gap: 1rem;
    scroll-behavior: smooth;
    max-width: 500px;
  }

  .reservation-box {
    width:80% !important;
    flex-shrink: 0;
  }

@media (max-width: 768px) {
  .info-container.grid.d-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .center {
    text-align: left;
    flex: 1;
  }
}

@media (max-width: 768px) {
  .info-container.d-flex {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .center {
    text-align: left;
    flex: 1;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .reservation-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    gap: 1rem;
  }

  .reservation-box {
    width:88% !important;
    flex-shrink: 0;
  }
}

.reservation-card {
  width: 94%;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.room-name {
  display: flex;

  .capacity {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
}
.reservation-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
}

.reservation-description {
  width: 100%;
  flex: 1 1 auto;

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .center {
      // text-align: center;

      p {
        font-size: 0.8rem;
        margin: 0;
      }
    }
  }

  .breakline {
    width: 100%;
    border-bottom: 1px solid black;
    text-align: right;
    cursor: pointer;
    img {
      width: 2%;
    }
  }
}

.hub-img-zoom {
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  height: 362px;
  margin-right: 2rem;
  overflow: hidden;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  .hub-img {
    transition: transform 0.5s ease;
    object-fit: cover;
    height: 100%;
    width: 100%;
    aspect-ratio: 16/10;
  }

  .btn-galerie {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 152px;

    .galerie-icon {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 60px;
      padding: 1rem 2rem;
      color: white;
      width: 152px;
      height: 48px;
    }
  }

  .hub-img:hover {
    transform: scale(1.1);
  }
}

.box-price {
  width: 100%;
  grid-template-rows: auto auto auto;
  min-height: 120px;
  margin: auto;
  margin-top: 2rem;
  border: 1px solid grey;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.box-price-two-col {
  grid-template-columns: repeat(2, 1fr);
}

.box-two-col {
  grid-column: 1 / 3; 
}

.box-price-no-search {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-middle {
  margin: auto;
  padding: 10px;
  .taxes {
    background-color: #f7f7f7;
  }
}
.box-middle-three {
  margin: auto;
  padding: 10px;
  .taxes {
    background-color: #f7f7f7;
  }
}

.box-middle-two {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .taxes {
    background-color: #f7f7f7;
  }
}

.reservation-box {
  width: 100%;
  // height: 224px;
}

.green-inputer {
  background-color: $light-kaki;
  color: white;
  border: none;
  padding-left: 17px;
  width: 22%;
}
@media only screen and (max-width: 480px) {
  .box-middle-two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 5px;
  }

  .box-middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .box-middle-three {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 10px;
    flex-wrap: wrap;
  }
  .mobile-gridd{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5px;
  }

  .box-middle p,
  .box-middle .taxes {
    flex: 1;
    margin: 0;
    padding: 8px;
  }

  .taxes {
    flex-basis: 100%; 
    margin-top: 5px;
    text-align: left;
  }
.mobile-member-section {
  margin-top: 1rem;
  text-align: center;
}

.member-btn {
  background-color: black;
  color: white;
  border: none;
  padding: 0.8rem;
  width: 100%;
  cursor: pointer;
  font: normal normal bold 12px/35px Raleway;
  letter-spacing: -0.12px;
  color: #FFFFFF;
  transition: background-color 0.3s, color 0.3s;
}

.member-btn.active {
  background-color: white;
  color: black;
  border: 1px solid black;
  font: normal normal bold 12px/35px Raleway;
  letter-spacing: -0.12px;
}

.member-menu {
  background-color: black;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 1rem;
  text-align: left;
}

.member-menu h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.member-menu ul {
  list-style: none;
  padding: 0;
}

.member-menu ul li {
  margin-bottom: 0.5rem;
}

.member-menu .btn-member {
  display: block;
  width: 100%;
  background-color: white;
  color: black;
  border: none;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 1rem;
  cursor: pointer;
}

}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobile-bullet {
    // padding-top: 0px !important;
    // padding-left: 0px !important;
    // padding-bottom: 0px !important;

  }

.slider-container {
  position: relative;
  width: 100%;
  margin-top: 4.2vh;
  max-width: 800px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  width: 100%;
  height: 38vh;
  object-fit: cover;
  border-radius: 1px;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  border-radius: 50%;
  width: 3.1rem;
  height: 3.1rem;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}


.arrow:hover {
  opacity: 1;
}

.arrow-image {
  width: 0.7rem;
  height: auto;
}

.left-arrow {
  left: 2px;
}

.right-arrow {
  right: 2px;
}



.image-counter {
  position: absolute;
  bottom: 20px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.9rem;
  z-index: 10;
}
.show-more-btn {
  color: #3B6152;
}
  .reservation-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;
  }
  .reservation-description {
    width: 100%;

    .grid {
      display: flex;
      flex-direction: column;
    }
    .center {
      margin-top: 1rem;
    }
  }
  .hub-img-zoom {
    width: 100%;
    height: 224px;
    margin-right: 2rem;
    overflow: hidden;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .box-price {
    display: flex;
    flex-direction: column;
  }
}

.member-menu h2 {
  font: normal normal bold 22px/26px Raleway;
  color: #FFFFFF;

}

.member-menu p {
  font: normal normal normal 16px/29px Raleway;
  letter-spacing: -0.08px;
  color: #FFFFFF;

}

.member-menu ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
  font: normal normal bold 16px/19px Raleway;
  letter-spacing: -0.16px;
  color: #FFFFFF;
  }

.member-menu ul li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font: normal normal bold 16px/19px Raleway;
  letter-spacing: -0.16px;
  color: #FFFFFF;
}
.already-member {
  font: normal normal normal 16px/29px Raleway;
letter-spacing: -0.08px;
color: #FFFFFF;
}

.already-member a {
  color: white;
  text-decoration: underline;
}

.already-member a:hover {
  text-decoration: none;
}

.note {
  font: normal normal normal 16px/29px Raleway;
  letter-spacing: -0.08px;
  color: #FFFFFF;
  line-height: 1.4;
  margin-top: 1rem;
}
.custom-tick-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-tick-list li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: white;
}

.custom-tick-list li::before {
  content: "✔";
  font-size: 0.8rem;
  color: #3b6152;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border-radius: 50%;
  margin-right: 10px;
  opacity: 1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
._sub_title_right {
  color: white;
  font: normal normal bold 16px/32px Raleway;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
}
.delice-section {
  grid-column: 1 / -1;
  display: flex;
  // align-items: center;
  // justify-content: flex-start;
  height: 6vh;
  background-color: black;
  border-bottom: 1px solid #ddd;
}

.full-width {
  width: 100% !important;
}

.normal-width {
  width: 80% !important;
}


.reservation-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem;
  scroll-behavior: smooth;
  max-width: 500px;
}

.reservation-box {
  flex: 0 0 auto;
  width: 300px; 
  box-sizing: border-box; 
}


.reservation-container.same-height .reservation-box {
  height: auto; /* Default height */
}

.reservation-box-content {
  flex-grow: 1; /* Ensures content stretches evenly */
}



.bullet-indicators {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.bullet {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bullet.active {
  background-color: #000;
}
.box-price {
  border: 1px solid #000;
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  font-family: $Raleway;
}

.conditions {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2px;
  text-align: left !important;
}

.conditions p {
  margin: 4px 0;
  color: #000;
  font-family: $Raleway;
}

.pricing-section {
  display: flex;
  flex-direction: column;
  font-family: $Raleway;
}

.price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
  font-family: $Raleway;
}

.price-row.total {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-family: $Raleway;
}

.total-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.price-note {
  font-size: 14px;
  color: #555;
  text-align: left;
  width: 100%;
  font-family: $Raleway;
  font-style: italic;
}

.price-row.totall {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding-left: 12px; 
  padding-right: 12px;
  font-family: $Raleway;
}

.hortense-btn-primary {
  background-color: #2c5e4b;
  color: #fff;
  padding: 10px 16px;
  border: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.hortense-btn-primary:hover {
  background-color: #1d4537;
}

.button-container {
  display: flex;
  justify-content: center;
  padding: 8px;
}
.conditions {
  margin-bottom: 2px; 
}

.pricing-section {
  margin-bottom: 1px; 
}
@media (max-width: 600px) {
  .box-price {
  border: 1px solid #000;
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  font-family: $Raleway;
}

.conditions {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2px;
  text-align: left !important;
}

.conditions p {
  margin: 4px 0;
  color: #000;
  font-family: $Raleway;
}

.pricing-section {
  display: flex;
  flex-direction: column;
  font-family: $Raleway;
}

.price-row {
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  font-family: $Raleway;
}

.price-row.total {
  font-weight: bold;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding-left: 12px; 
  padding-right: 12px;
  border-radius: 4px;
  font-family: $Raleway;
}
.price-row.totall {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding-left: 12px; 
  padding-right: 12px;
  font-family: $Raleway;
}
.price-note {
  font-size: 14px;
  color: #555;
  text-align: left;
  font-family: $Raleway;
}

.hortense-btn-primary {
  background-color: #2c5e4b;
  color: #fff;
  padding: 10px 16px;
  border: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.hortense-btn-primary:hover {
  background-color: #1d4537;
}

.button-container {
  display: flex;
  justify-content: center;
  padding: 8px;
}
.conditions {
  margin-bottom: 2px; 
}

.pricing-section {
  margin-bottom: 1px; 
}

}

</style>
