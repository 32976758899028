
<template>
  <div id="homePhone" style="overflow-x: hidden;">
    <BannerPhone></BannerPhone>
    <PropositionsPhone></PropositionsPhone>
    <ExperienceHortense @connection="openModal" />
    <SeasonsCard></SeasonsCard>
    <AccommodationPhoneCard :user='user' :lodgings="accommodations" v-if='accommodations.length > 0'></AccommodationPhoneCard>
    <LodginghomePhone @connection="openModal" :user='user' :lodgings="lodgings" v-if='lodgings.length > 0'></LodginghomePhone>
<!--    <ExclusiveHomePhone :exclusives="exclusives" v-if='exclusives.length > 0'></ExclusiveHomePhone>-->
<!--    <WeekendPhone :weekends="weekends" v-if='weekends.length > 0'></WeekendPhone>-->
<!--    <ExpehomePhone :experiencesByCategory="experiences" v-if=" Object.keys(experiences).length > 0"></ExpehomePhone>-->
    <GiftCardPhone></GiftCardPhone>
    <DestinationPhone :destinations="destinations" v-if='destinations.length > 0'></DestinationPhone>
    <CollectionHomePhone :collections='collections' v-if='collections.length > 0'></CollectionHomePhone>
    <TipsPhone></TipsPhone>
    <EvergreenPhoneHome></EvergreenPhoneHome>
    <LabelhomePhone></LabelhomePhone>
    <WhoisPhone></WhoisPhone>
    <FidPhone :user='user' @connection="openModal"></FidPhone>
    <ScrollUp></ScrollUp>
    <a class="sticky hortense-btn-primary raleway-bold-p" href="/reservation-hotel" v-if="scrolled">{{ $t('LodgingCard.view') }}</a>
    <!-- <ReaPhone></ReaPhone> -->

  </div>
</template>

<script>
  import fetcher from 'packs/shared/api.js';
  import BannerPhone from '../components/BannerPhone.vue';
  import PropositionsPhone from 'components/PropositionsPhone.vue';
  import TipsPhone from 'components/TipsPhone.vue';
  import LodginghomePhone from 'components/LodginghomePhone.vue';
  import WeekendPhone from 'components/WeekendPhone.vue';
  import ExclusiveHomePhone from 'components/exclusive/ExclusiveHomePhone.vue';
  import ExpehomePhone from 'components/ExpehomePhone.vue';
  import GiftCardPhone from "../components/GiftCardPhone.vue";
  import DestinationPhone from 'components/DestinationPhone.vue';
  import CollectionHomePhone from 'components/collection/CollectionHomePhone.vue';
  import EvergreenPhoneHome from 'components/EvergreenPhoneHome.vue';
  import LabelhomePhone from 'components/LabelhomePhone.vue';
  import WhoisPhone from 'components/WhoisPhone.vue';
  import FidPhone from 'components/FidPhone.vue';
  import ScrollUp from 'components/btns/ScrollUp.vue';
  import AccommodationPhoneCard from "../components/cards/AccommodationPhoneCard.vue";
  import ExperienceHortense from "../components/ExperienceHortense.vue";
import SeasonsCard from '../components/cards/SeasonsCard.vue';
  // import ReaPhone from 'components/ReaPhone.vue';

  export default {
    name: 'homePhone',
    props: ['user'],
    components: { ExperienceHortense, BannerPhone, TipsPhone, PropositionsPhone, LodginghomePhone, WeekendPhone, ExclusiveHomePhone, ExpehomePhone, DestinationPhone, CollectionHomePhone, EvergreenPhoneHome, LabelhomePhone, WhoisPhone, FidPhone, ScrollUp, GiftCardPhone, AccommodationPhoneCard, SeasonsCard },
    data() {
      return {
        showBanner: false,
        lodgings: [],
        weekends: [],
        exclusives: [],
        experiences: {},
        destinations: [],
        collections: [],
        evergreens: [],
        scrolled: false,
        accommodations: [],
      }
    },
    methods: {
      openModal() {
        this.$root.$emit('openModal')
      },
      redirectToTop() {
         window.scrollTo(0, '#navbar');
      },
      handleScroll () {
        const top =	window.pageYOffset;
        const bottom = document.body.scrollHeight - (window.innerHeight + window.scrollY);
        const footer_height = document.getElementById('p-footer').offsetHeight;
        if (top > 52 && bottom > footer_height) {
          this.scrolled = true;
        } else {
          this.scrolled = false;
        }
      },
      async fetchdatas() {
        try {
          const responseLodging = await fetcher.get('/reservation-hotel?page=1&lodgings_count=3');
          this.lodgings = responseLodging.data;
          const responseAccommodation     = await fetcher.get(
              "/home_lodgings"
          );
          this.accommodations = responseAccommodation.data;
          console.log(this.lodgings)
          const responseWeekend = await fetcher.get('/weekends');
          this.weekends = await responseWeekend.data;
          const responseExclusive = await fetcher.get('/offres-exclusives');
          this.exclusives = await responseExclusive.data;
          const responseExperience = await fetcher.get('/basic/experiences');
          this.experiences = responseExperience.data;
          const responseDestination = await fetcher.get('/destination');
          this.destinations = await responseDestination.data;
          const responseCollection = await fetcher.get('/collections');
          this.collections = responseCollection.data;
          const responseEvergreen = await fetcher.get('/evergreens');
          this.evergreens= await responseEvergreen.data;
        } catch(e) {
          console.error(e);
        }
      },
    },
    mounted() {
      this.fetchdatas();
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }
</script>
