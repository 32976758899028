<template>
    <div id="banner">
      <video
          autoplay
          loop
          muted
          poster="../../images/vacances-ete-d.jpg"
          class="home_video"
          @timeupdate="updateText"
        >
          <source src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances-ete2+(2).mp4">
        </video>
      <div class="banner-container">
        <div class="banner-text">
        <h2 class="banner-text--title" v-if="currentText" v-html="currentText"></h2>
      </div>
        <div class="banner-search">
          <MainSearch></MainSearch>
        </div>
      </div>
      <div class="logo-see-more" @mouseover="animLogo" @click="scrollDown">
        <img src="../../images/fleche blanc.svg" v-set-alt>
      </div>
    </div>
  </template>
  
  
  <script>
  import MainSearch from "../btns/MainSearch.vue";
  
  export default {
    name: "summer-banner",
    components: {
      MainSearch,
    },
    data() {
      return {
        search: "",
        currentText: '',
        captions: [
          { time: [0, 4], text: "Et si vous viviez l’<em><strong>été autrement</strong></em> ?" },
          { time: [4, 7], text: "<strong>Évadez-vous</strong> à deux, <strong>ressourcez-vous</strong> pleinement" },
          { time: [7, 11], text: "Partagez des <em><strong>souvenirs inoubliables</strong></em>" },
          { time: [11, 14], text: "Vivez chaque séjour comme une nouvelle <em><strong>expérience de voyage</strong></em>" },
          { time: [14, 18], text: "Reconnectez-vous à l’<em>essentiel</em>" },
          { time: [18, 21], text: "Offrez-vous un <em><strong>été d’exception</strong></em>" },
          { time: [21, 27], text: "Vivez l’<em><strong>été autrement</strong></em> !" },
          { time: [27, 30], text: "Réservez dès maintenant sur <em><strong>Hortense.green</strong></em>" },
        ],
      };
    },
    methods: {
      handleVideoPlay() {
        const video = this.$el.querySelector(".home_video");
        video.classList.add("fade-in-video"); // Add fade-in class when video starts
      },
      updateText(event) {
      const currentTime = event.target.currentTime;
      const caption = this.captions.find(c => currentTime >= c.time[0] && currentTime < c.time[1]);
      this.currentText = caption ? caption.text : '';
    },
      animLogo(e) {
        e.currentTarget.animate(
          [
            { transform: "translateY(0px)" },
            { transform: "translateY(-10px)" },
            { transform: "translateY(0px)" },
          ],
          {
            duration: 800,
            easing: "ease-in-out",
            iterations: 2,
          }
        );
      },
      scrollDown() {
        this.$el.nextElementSibling.scrollIntoView({ behavior: "smooth" });
      },
    },
  };
  </script>
  
  
  <style lang="scss" scope='app/assets/stylesheets/components/banner'>
 .home_video {
    max-width: 1920px;
    max-height: 1080px;
    width: 100%;
    object-fit: contain;
    height: auto;
    max-width: 1920px;
    position: absolute;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    background-size: cover;
    object-fit: cover;
    opacity: 1 !important;
    transition: opacity 1s ease-in-out;
  }
  
  .home_video.fade-in-video {
    opacity: 1; // Video becomes visible when ready
  }
  
  .banner-container {
    position: relative;
  }
  
  .banner-text {
    text-align: center;
  
    &--title {
      font-size: 40px;
      color: white;
    }
  
    &--para {
      font-size: 15px;
      color: white;
      text-transform: uppercase;
    }
  }
  
  .logo-see-more {
    position: absolute;
    bottom: 20px;
    cursor: pointer;
  }
  .banner-text--title em strong {
    font-weight: 700 !important;
  }
  
  </style>
  