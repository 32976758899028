<template>
  <div id="lodginghome-phone">
    <div class="phone-container">
      <div class="lodging-header">
      </div>
      <div id="myscroller" class="card-lodging-container" style="padding-bottom: 15px;">
        
        <div 
          class="card-hub" 
          @click="openDestination('https://www.hortense.green/destination/corse')"
        >
          <div class="position-relative">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/corse-vacances-ete.webp" class="prop-img" v-set-alt>
          </div>
        </div>

        <div 
          class="card-hub" 
          @click="openDestination('https://www.hortense.green/destination/provence-alpes-cote-d-azur')"
        >
          <div class="position-relative">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/provences-alpes-cote-azur-vacances-ete.webp" class="prop-img" v-set-alt>
          </div>
        </div>

        <div 
          class="card-hub" 
          @click="openDestination('https://www.hortense.green/destination/nouvelle-aquitaine')"
        >
          <div class="position-relative">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/nouvelle-aquitaine-vacances-ete.webp" class="prop-img" v-set-alt>
          </div>
        </div>

        <div 
          class="card-hub" 
          @click="openDestination('https://www.hortense.green/destination/occitanie')"
        >
          <div class="position-relative">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/occitanie-vacances-ete.webp" class="prop-img" v-set-alt>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lieux-prepositions-phone',
  data() {
    return {
      options: {
        arrows: 0,
        rewind: true,
        width: '100%',
        height: 304,
        padding: {
          right: '7rem',
        },
        perMove: 1,
        gap: '1rem',
        classes: {
          pagination: 'splide__pagination  pagi-phone', // container
          page: 'splide__pagination__page dot-phone', // each button
        }
      }
    };
  },
  methods: {
    openDestination(baseUrl) {
      const baseParam = this.baseQuery ? `?base=${this.baseQuery}` : '';
      const finalUrl = `${baseUrl}${baseParam}`;
      window.open(finalUrl, '_blank');
    },
    autoScroll() {
      const scroller = document.getElementById('myscroller');
      let maxScrollLeft = scroller.scrollWidth - scroller.clientWidth;

      const step = 1; // Adjust step for speed
      const frame = () => {
        if (scroller.scrollLeft >= maxScrollLeft) {
          scroller.scrollLeft = 0; // Reset scroll to start when it reaches the end
        } else {
          scroller.scrollLeft += step;
        }
        requestAnimationFrame(frame);
      };
      frame();
    }
  },
  computed: {
    baseQuery() {
      const path = window.location.pathname;
      if (path.includes("/vacances-printemps")) {
        return 'spring';
      } else if (path.includes("/vacances-ete")) {
        return 'summer';
      }
      return '';
    }
  },
  mounted() {
    this.autoScroll();
  }
}
</script>

<style lang="scss" scope='app/assets/stylesheets/components/tips-phone'>
.anchor {
  text-decoration: none;
  font-family: $Raleway;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}

.prop-img {
  height: 270px !important;
  width: 295px !important;
}

.element {
  width: 100%;
}
</style>