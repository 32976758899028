<template>
  <div id="p-footer">
    <div class="footer-container">
      <Modalloginpartner v-if="showModalloginpartner" @close="setUser"></Modalloginpartner>
      <Subscribe v-if="showModalSubscribe" @closeSubscribe="closeSubscribe" @close="showModalSubscribe = false"></Subscribe>
      <div class="footer-rect">
        <ul class="footer-list">
          <li class="list-item-footer">
            <a :href="'/qui-est-hortense-voyage-ecoresponsable/'">{{ $t("Hortense.whois") }}</a>
          </li>
           <li class="list-item-footer">
<!--            <a :href="'/offres-exclusives/'">{{ $t("Hortense.exclusive") }}</a>-->
          </li>
          <li class="list-item-footer">
            <a href="https://www.blog.hortense.green/">{{ $t("newspaper") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/programme-fidélité-hortensia/'">{{ $t("Hortense.loyalty") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/faq-hortense/'">{{ $t("Hortense.faq") }}</a>
          </li>
          <li class="list-item-footer">
            <a href="/hortense-for-business">{{ $t("Hortense.business") }}</a>
          </li>
          <li class="list-item-footer">
            <a href="/workation">Workation</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/pages/ethic/'">{{ $t("Hortense.ethic") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/carte-cadeau'">{{ $t("Hortense.gift_cards") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/hortense-for-wedding'">{{ $t("Hortense.for_wedding") }}</a>
          </li>
          <li class="list-item-footer">
            <a :href="'/voyage-sur-mesure'">{{ $t("Hortense.made_trip") }}</a>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-6 col-footer">
          <h3 class="grid-title">{{ $t("contact") }}</h3>
          <img @click="openWhatsApp" src="../../images/WHATSAPP_img_crop.png" style="display: block; margin: 0; padding: 0; height: 4vh; object-fit: contain; vertical-align: middle;" v-set-alt class="whatsapp-icon mt-2 mb-2" />
          <h3> {{ $t("message") }} </h3>
          <a :href="'/pages/contact/'"> {{ $t("write") }} </a>
        </div>
        <!-- <div class="col-6 col-footer">
          <h3 class="grid-title mb-5">{{ $t("join") }}</h3>
        </div> -->
      </div>



      <div class="row">
       <div class="col-11 col-footer">
          <h3 class="grid-title mb-1">LA NEWSLETTER D’HORTENSE</h3>
          <p class="grid-content">{{ $t("Hortense.privacy") }}</p>
          <button @click="showModalSubscribe = true">{{ $t("suscribe") }}</button>
        </div>
      </div>


      <div class="col-6 col-footer">
        <h3 class="grid-title mt-5">{{ $t("join") }}</h3>
        <ul class="grid-list mt-3">
         <li class="grid-list-item pinterest">
            <a target="_blank" href="https://www.pinterest.fr/Hortense_green/">
            <i class="fa-brands fa-pinterest-p fa-2xl"></i>
            </a>
          </li>
          <li class="grid-list-item linkedin">
            <a target="_blank" href="https://www.linkedin.com/company/hortense-green/">
              <i class="fa-brands fa-linkedin fa-2xl"></i>
            </a>
          </li>
          <li class="grid-list-item instagram">
            <a target="_blank" href="https://www.instagram.com/hortense.green_/">
              <i class="fa-brands fa-instagram-square fa-2xl"></i>
            </a>
          </li>
          <li class="grid-list-item facebook">
            <a target="_blank" href="https://www.facebook.com/maketravelgreen/">
              <i class="fa-brands fa-facebook-square fa-2xl"></i>
            </a>
          </li>
          <li class="grid-list-item youtube">
            <a target="_blank" href="https://www.youtube.com/channel/UCRbKxuYZQowvCngbGeCVvMg">
              <i class="fa-brands fa-youtube fa-2xl"></i>
            </a>
          </li>
        </ul>
      </div>

      <div class=" col-footer mt-5">
        <button @click="openHostUrl">{{ $t("host") }}</button>
      </div>

      <div class="link-footer">
        <ul class="list-link">
          <li class="link-item link-pages">
            <a :href="'/conditions-generales-utilisation/'">CGU</a>
          </li>
          <li class="link-item link-pages">
            <a :href="'/conditions-generales-ventes/'">CGV</a>
          </li>
          <li class="link-item link-pages">
            <a :href="'/mentions-legales/'">{{ $t("legals") }}</a>
          </li>
          <li class="link-item link-pages">
            <a :href="'/politique-confidentialité/'">{{ $t("policy") }}</a>
          </li>
          <li class="link-item link-pages">
            <a href="">{{ $t("payment") }}</a>
          </li>
        </ul>
        <ul class="list-picto">
          <li class="link-item">
            <img style="height: 5rem;" src="../../images/payment-1.svg" v-set-alt>
          </li>
          <li class="link-item">
            <img style="height: 5rem;"  src="../../images/payment-2.svg" v-set-alt>
          </li>
          <li class="link-item">
            <img style="height: 5rem;" src="../../images/payment-3.svg" v-set-alt>
          </li>
          <li class="link-item">
          <img style="height: 5rem;" src="../../images/payment-4.svg" v-set-alt>
          </li>
          <li class="link-item">
            <img style="height: 5rem;" src="../../images/payment-5.svg" v-set-alt>
          </li>
        </ul>
      </div>


    </div>
  </div>
</template>

<script>

  import Modalloginpartner from 'components/modals/Modalloginpartner.vue';
  import Subscribe from 'components/modals/Subscribe.vue';
  import Flash from 'alert/Flash.vue';

  export default {
    name: "p-footer",
    components: { Modalloginpartner, Subscribe, Flash },
    data() {
      return {
        showModalloginpartner: false,
        showModalBecome: false,
        showModalSubscribe: false,
        isActiveHortense: false,
        isActiveCurrencies: false,
        selectedCurrency: "FR/EUR",
        currencies: [{"currency": "FR/EUR", "lang": "fr"}, {"currency": "UK/GBP", "lang": "en"}, {'currency': "US/USD", "lang": "en"}],
        locale: 'fr',
        origin: window.location.origin,
        text: '',
        status: '',
        userInfo: {
          email: '',
          password: '',
        },
      }
    },
    watch: {
      locale(val) {
        this._i18n.locale = val
      }
    },
    methods: {
      async UserBasicInfo() {
        try {
          const response = await fetcher.post('/users/sign_in', {
                                                            user: {
                                                              email: this.userInfo.email,
                                                              password: this.userInfo.password,
                                                            }
                                                          })
          this.$emit('close', response.data);
          location.reload();
        } catch(e) {
          console.log(e);
        }
      },
      openHostUrl(){
        Turbolinks.visit('/devenir-hote')
      },
      setUser(user){
        if (user) {
          this.user = user;
          this.showModalloginpartner = false;
          this.$emit('close', user);
        } else {
          this.showModalloginpartner = false;
        }
      },
      closeSubscribe() {
        this.showModalSubscribe = false;
      },
      showHortense() {
        this.isActiveHortense = !this.isActiveHortense;
      },
      showCurrencies(e) {
        const el = e.currentTarget.lastElementChild;
        el.style.transform = el.style.transform ? '' : 'rotateZ(180deg)';
        this.isActiveCurrencies = !this.isActiveCurrencies;
      },
      currenciesLeft() {
        return this.currencies.filter(x => {return x.currency !== this.selectedCurrency })
      },
      selectCurrency(e) {
        this._i18n.locale = e.currentTarget.value;
        this.selectedCurrency = e.currentTarget.innerText;
        this.isActiveCurrencies = false;
        const el = document.querySelector('.arrow');
        el.style.transform = el.style.transform ? '' : 'rotateZ(180deg)';
      },
      toggleStatus(value="success") {
        this.status = this.status === value ? null : value
      },
      openWhatsApp() {
        window.open("https://wa.me/33626203846", "_blank");
      }
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/p-footer'>




</style>
