<template>
	<div id="lodging-all">
		<div v-if="showMap && isPhone" class="full-page-modal">
			<div class="modal-header">
				<button class="close-btn" @click="toggleMap">×</button>
			</div>
			<div id="map-test">
				<Maptest
				:markers="markers"
				:lodgings="previous_lodging"
				@markerSelected="selectCardMap"
				:selectCardMap="selectCardMap"
				:selectCard="selectCard"
				@close="toggleMap"
				/>
			</div>
			</div>
		<div class="container-index custom-index"  >
			<div class="desti-header text-center">
				<h1 v-if="this.search-type == 'country'" class="merriweather-reg-40 mt-4 lodge-title">{{ getGroupTitle }}</h1>
				<h2 v-if="this.search-type != 'country'" class="merriweather-reg-40 mt-4 lodge-title">{{ getGroupTitle }}</h2>
				<h1 v-if="this.searchType !== 'country'" class="merriweather-italic24 lodge-description mt-3">{{ getGroupSubTitle }}</h1>
				<p class="raleway-reg-p15 mt-4">{{ getGroupText }}</p>
				<p class="raleway-reg-p15" v-if="this.lodgingsCounter !== 0">{{ this.formatLodgings() }}</p>
			</div>
      <div id="search-bannerr" ref="searchBar">
        <SearchLodging v-if="!isPhone" @updateFilteredLodgings="updateFilteredLodgings" @selectDates="updateDates" @close="false"></SearchLodging>
      </div>
			<div class="btn-filters mt-4">
				<div class="green-note-filter mr-3 df-jsb-aic" @click="sortByNote">
					<p class="raleway-reg-p13 mb-0 mr-3">Green note</p>
					<img src="../../images/fleches dropdown.svg" v-set-alt />
				</div>
				<div v-if="this.lodgingsCounter !== 0" class="map-redirection" @click="displayMap">
					<p class="raleway-reg-p13 mb-0">Carte</p>
				</div>
			</div>
			<div class="filters-phone">
				<p class="raleway-reg-p15 mb-0" @click="showFilters" v-if="showFilt">Masquer les filtres</p>
				<p class="raleway-reg-p15 mb-0" @click="showFilters" v-else>Appliquer les filtres</p>
			</div>
			<div class="row row-page">
				<div class="col-lg-3 col-xs-0 col-filter">
					<Filterlodgings
						:stylesMount="stylesMount"
						:servicesMount="servicesMount"
						:desiresMount="desiresMount"
						@checked="setData"
						@switch="setData"
						@changedBudget="setData"
            @changedAdults="setData"
            @changedChilds="setData"
					>
					</Filterlodgings>
				</div>
				<div class="col-lg-9 col-xs-10">
					<div class="lodgings">
						<Maptest v-if="showMap && !isPhone" :markers="this.markers"  :lodgings="this.previous_lodging" @markerSelected="selectCardMap" :selectCardMap="selectCardMap" :selectCard="selectCard" @close="showMap = false"></Maptest>
						<div v-if="!selectedMarker">
							<CardProductbig
									v-for="(lodging, index) in this.filteredLodgings"
									:key="index"
									:wishlistLodgings="wishlistLodgings"
									:user="isUserConnected()"
									:lodging="lodging"
                  :to="to"
                  :from="from"
									:destination="lodging.destination"
									v-if="index === 0"
									:data-marker="index"
									:data-lodging="lodging.id"
									v-bind="!isPhone ? { 'data-aos': 'fade-up', 'data-aos-duration': '900' } : {}"
									v-show="false"
							></CardProductbig>
							<CardProductbig
							        :wishlistLodgings="wishlistLodgings"
									:lodging="lodging"
									:user="isUserConnected()"
									:destination="lodging.destination"
                      :to="to"
                      :from="from"
									v-else
									style="margin-top: 20px"
									:data-marker="index"
									:data-lodging="lodging.id"

									v-show="false">
							</CardProductbig>


							<CardProductbig
								v-for="(lodging, index) in this.displayedLodgs"
								:key="index"
								:wishlistLodgings="wishlistLodgings"
								:user="isUserConnected()"
								:lodging="lodging"
								:destination="lodging.destination"
                :to="to"
                :from="from"
								v-if="index === 0"
								:data-marker="index"
								:data-lodging="lodging.id"
								v-bind="!isPhone ? { 'data-aos': 'fade-up', 'data-aos-duration': '900' } : {}"
								@hideBigMap="hideBigMap"
								@hideSmallMap="hideSmallMap"

								:showMapCard="showMapCard"
							></CardProductbig>
							<CardProductbig
							    :wishlistLodgings="wishlistLodgings"
							    :lodging="lodging"
								:user="isUserConnected()"
								:destination="lodging.destination"
                  :to="to"
                  :from="from"
								v-else
								style="margin-top: 20px" :data-marker="index"
								:data-lodging="lodging.id"
								@hideBigMap="hideBigMap"

								@hideSmallMap="hideSmallMap"
								:showMapCard="showMapCard"
							></CardProductbig>
						</div>
						<div v-else>
<!--							<Cardmap :lodging="this.selectedLodging" :destination="this.selectedLodging.destination" @unselect="selectCardMap" :idMarker="selectedCard"></Cardmap>-->
						</div>
					</div>
					<nav v-if="lodgingsCounter !== 0 && !selectedMarker" class="mt-5">
								<ul class="pagination">
									<li class="page-item"
									        @click="scrollToTop">
										<button
										    v-for="pageNumber in pages"
											:class="page_number_count === pageNumber ? 'active-item' : ''"
											type="button"
											class="page-link"
                      @click="fetchLodgings(pageNumber)">
											{{pageNumber}}
										</button>
									</li>
								</ul>
					</nav>
					<div v-if="lodgingsCounter === 0" class="no-lodging text-center raleway-reg-p15">
<!--						<p>Aucun hébergement disponible.</p>-->
						<p> Hortense est à la recherche de lieux d’exception à vous proposer dans cette destination.
						    Si vous souhaitez que nous vous trouvions un hébergement de charme dans une destination spécifique, <strong>n'hésitez pas à nous contacter
							et à nous fournir les détails de votre demande.</strong></p>
						<p>Que ce soit un hôtel de charme en pleine nature, une chambre d'hôtes à la campagne, une villa en bord de mer, un chalet en montagne ou un boutique hôtel
						en pleine ville, nous mettrons tout en œuvre pour trouver l'endroit parfait correspondant à vos attentes.</p>
						<a :href="'/voyage-sur-mesure/'" class="hortense-btn-primary" :class="{'cta': !isPhone}">VOTRE VOYAGE SUR MESURE</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CardProductbig from '../../components/cards/CardProductbig.vue';
	import Filterlodgings from '../../pages/destinations/Filterlodgings.vue';
	import Cardmap from '../../components/cards/Cardmap.vue';
	import Maptest from '../../components/Maptest.vue';
	import fetcher from '../../packs/shared/api.js';
	import AOS from 'aos';
  import SearchLodging from "../../components/btns/Searchlodging.vue";
	export default {
		name: 'lodgings',
		components: {SearchLodging, CardProductbig, Filterlodgings, Maptest, Cardmap },
		props: ['pagedNumber','lodgings', 'user', 'search-type', 'page-description', 'page-subtitle', 'page-name', 'services', 'styles', 'desires', 'type'],
		data() {
			return {
        		previous_lodging: [],
				paged_number: this.pagedNumber,
				pagedLodgings: [],
				showMapCard: false,
				wishlistLodgings: null,
				page: 1,
        page_number_count: 1,
			    perPage: 10,
			    pages: [],
				lodgingsList: null,
				filteredLodgings: this.lodgings,
				showMap: false,
				markers: [],
				selectedMarker: false,
				selectedLodging: null,
				showFilt: false,
				selectedCard: null,
				current: 1,
				asc: true,
				allValues: {
					score: [null, '4', '5', '6', '7', '8', '9', '10'],
					standing: [null, '0', '1', '2', '3', '4', '5'],
					type: [null, 'Hotel', 'Guestroom', 'Gite', 'Guesthouse', 'Other', 'Villa', 'Appartment', 'Lodge'],
					budget: [0, 5000],
          childs: 0,
          adults: 2,
					//beddings: [null, 'Lit double - Standard', 'Lit double - King size', 'King size', 'king size', 'Queen size', 'Standard', 'Lit double - Queen size', 'queen-size', 'Lit double', 'Lit simple', 'Lits jumeaux', 'Lit double - King size ', 'Lits supplémentaires en option ', 'Lit double - Queen Size', 'Canapé convertible en lit 2 places', 'Lit double - Queen Size '],
					breakfast_include: false,
					half_pension: false,
					full_pension: false,
					all_inclusive: false,
					animal: false,
					business: false,
					freeCancel: false,
          services: [],
          styles: [],
          desires: [],
          eq_services: [],
          delice: false,
          escapade: false,
          evasion: false,
          plaisirs: false
				},
        koediaFrom: null,
        koediaTo: null,
        from: null,
        to: null,
				activeFilters: {
          score: [null, '4', '5', '6', '7', '8', '9', '10'],
          standing: [null, '0', '1', '2', '3', '4', '5'],
          type: [null, 'Hotel', 'Guestroom', 'Gite', 'Guesthouse', 'Other', 'Villa', 'Appartment', 'Lodge'],
          budget: [0, 2000],
          childs: 0,
          adults: 2,
          //beddings: [null, 'Lit double - Standard', 'Lit double - King size', 'King size', 'king size', 'Queen size', 'Standard', 'Lit double - Queen size', 'queen-size', 'Lit double', 'Lit simple', 'Lits jumeaux', 'Lit double - King size ', 'Lits supplémentaires en option ', 'Lit double - Queen Size', 'Canapé convertible en lit 2 places', 'Lit double - Queen Size '],
          breakfast_include: false,
          half_pension: false,
          full_pension: false,
          all_inclusive: false,
          animal: false,
          business: false,
          freeCancel: false,
          services: [],
          styles: [],
          desires: [],
          eq_services: [],
          delice: false,
          escapade: false,
          evasion: false,
          plaisirs: false
        },
				stylesMount: [],
				desiresMount: [],
				servicesMount: [],
			};
		},
		watch: {
			//lodgs(){
			//   this.setPages();
		    //},
			allValues: {
				deep: true,
				handler(val) {
					this.activeFilters = Object.assign({}, val);
				},
			},
			activeFilters: {
				deep: true,
				handler() {
					this.filter();
				},
			},
			lodgings: {
				immediate: true,
				handler(val) {
					this.lodgingsList = val.map((el) => JSON.parse(el));
					this.filteredLodgings = this.lodgingsList;
					this.setPages();
				},
			},
			filteredLodgings() {
					this.setPages();
			},
			desiresMount: {
				deep: true,
				handler(val) {
					if(val) {
						if (!val.length) return;
						this.allValues.desires = val.map((el) => el.id);
					}
				},
			},
			servicesMount: {
				deep: true,
				handler(val) {
					if(val) {
						if (!val.length) return;
						this.allValues.services = val.map((el) => el.id);
					}
				},
			},
			stylesMount: {
				deep: true,
				handler(val) {
					if(val) {
						if (!val.length) return;
						this.allValues.styles = val.map((el) => el.id);
					}
				},
			},
		},
		computed: {
			isPhone() {
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
					return true;
				}else{
					return false;
				}
			},
			getGroupTitle(){
				if(window.location.href.includes('/reservation-hotel')){
					return "Nos hébergements";
				}else if(window.location.href.includes('/hotel-de-charme')){
					return "Nos hôtels de charme";
				}else if(window.location.href.includes('/villa-de-charme')){
					return "Nos villas de charme";
				}else if(window.location.href.includes('/chambres-dhotes-de-charme')){
					return "Nos chambres d’hôtes de charme";
				}else if(window.location.href.includes('/logement-insolite-de-luxe')){
					return "Nos logements insolites de luxe";
				}else if(window.location.href.includes('/appartement-de-vacances')){
					return "Nos appartements de vacances";
				}else if(window.location.href.includes('/les-nouveautes-hortense')){
          return "Nos nouveautés";
        }else {
					return this.pageName;
				}
			},
			getGroupSubTitle(){
				if(window.location.href.includes('/reservation-hotel')){
					return "Les plus beaux hébergements éco-responsables";
				}else if(window.location.href.includes('/hotel-de-charme')){
					return "Séjournez dans les plus beaux hôtels de charme avec Hortense !";
				}else if(window.location.href.includes('/villa-de-charme')){
					return "Pour se sentir comme à la maison !";
				}else if(window.location.href.includes('/chambres-dhotes-de-charme')){
					return "Immergez-vous dans une atmosphère conviviale et accueillante avec Hortense !";
				}else if(window.location.href.includes('/logement-insolite-de-luxe')){
					return "Pour vivre une expérience unique et hors du commun !";
				}else if(window.location.href.includes('/appartement-de-vacances')){
					return "Découvrez des appartements d'exception pour des vacances mémorables";
				}else {
					return this.pageSubtitle;
				}
			},
			getGroupText(){
				if(window.location.href.includes('/reservation-hotel')){
					return "Amoureuse de la nature et avant tout dénicheuse de voyages, Hortense vous propose une sélection des plus beaux hébergements éco-responsables. Laissez-vous emporter par le meilleur du tourisme haut de gamme : services exceptionnels, expériences uniques et locales… Préparez-vous à passer un séjour inoubliable !";
				}else if(window.location.href.includes('/hotel-de-charme')){
					return "Découvrez notre sélection exclusive d'hôtels de charme, conçus pour une expérience inoubliable. Nichés en ville, campagne ou bord de mer, les hôtels éco-responsables de la collection Hortense vous offrent un service exceptionnel, du petit-déjeuner au Spa. Laissez-vous guider par notre personnel pour explorer la région et trouver des adresses gourmandes et culturelles. Notre objectif dans chaque hôtel Hortense : rendre vos vacances parfaites, en vous déchargeant de tout souci, pour que vous puissiez savourer pleinement chaque instant.";
				}else if(window.location.href.includes('/villa-de-charme')){
					return "Découvrez le luxe des villas et maisons Hortense ! Parfaites pour des vacances exclusives en famille ou entre amis, ces maisons intemporelles offrent confort et intimité. Chaque villa propose une architecture haut de gamme et un design élégant, pour des moments inoubliables. Profitez de services exceptionnels : piscine privée, accès plage et garde d'enfants. Offrez-vous une expérience inoubliable dans nos demeures où luxe et confort sont au rendez-vous pour des vacances exceptionnelles.";
				}else if(window.location.href.includes('/chambres-dhotes-de-charme')){
					return "Découvrez la collection Hortense de chambres d'hôtes de charme, où l'accueil chaleureux et convivial crée un séjour inoubliable. Chaque hôte vous reçoit avec attention, vous faisant sentir comme en famille. Plongez dans une ambiance intime, vous sentirez instantanément chez vous. Comptez sur les conseils de vos hôtes, experts locaux, pour explorer la région. Chaque chambre est unique, offrant charme et confort pour vous ressourcer. Réservez une chambre exceptionnelle avec Hortense et vivez une expérience incomparable de détente loin du stress quotidien.";
				}else if(window.location.href.includes('/logement-insolite-de-luxe')){
					return "Explorez une sélection unique de logements insolites de luxe avec Hortense ! Réveillez-vous au cœur de la nature, au-dessus des arbres ou près de l'eau. Découvrez des lieux uniques grâce à notre collection. Le voyage va au-delà de la destination : vivez des séjours étonnants dans des logements insolites. Chaque lieu offre un service haut de gamme, chambres équipées, accès à des espaces de bien-être et accueil personnalisé. Osez l'expérience pour vous reconnecter avec la nature. Cabanes dans les bois, bulles transparentes, maisons sur l'eau ou sous terre, vivez une escapade originale en solo, en couple, en famille ou entre amis !";
				}else if(window.location.href.includes('/appartement-de-vacances')){
					return "Optez pour un séjour d'exception en choisissant nos appartements de vacances Hortense. Luxe et confort s'unissent pour créer une expérience unique. Nos appartements de vacances offrent le plaisir d'être chez soi en vacances, avec équipements high-tech et installations de premier ordre. Profitez du bien-être total, libre de dîner à l'extérieur ou cuisiner chez vous, siroter un verre en terrasse ou vous détendre sur le canapé. Nos appartements de vacances reflètent le haut de gamme, spacieux et élégants, pour des vacances mémorables. Conçus pour le summum du confort et du service, chaque appartement vous assure une expérience exceptionnelle et inoubliable.";
				}else if(window.location.href.includes('/les-nouveautes-hortense')){
          return "Découvrez les nouveaux hébergements sélectionnés par Hortense pour des séjours uniques et écoresponsables. Que vous cherchiez une retraite en pleine nature ou une escapade culturelle, chaque lieu a été choisi pour son authenticité et son engagement durable. Avec Hortense, laissez-vous inspirer pour vivre des expériences inoubliables, dans des lieux où confort et respect de l'environnement se rencontrent.";
        }else {
					return this.pageDescription;
				}
			},
			displayedLodgs() {
				return this.paginate(this.filteredLodgings);
		    },
			lodgingsCounter() {
				return this.filteredLodgings.length;
			},
		},
		beforeMount(){
			this.fetchWishlist();
		},
		methods: {
			toggleMap() {
				this.showMap = !this.showMap;
				if (this.showMap) {
				document.body.classList.add("no-scroll");
				} else {
				document.body.classList.remove("no-scroll");
				}
			},
			hideBigMap() {
			   this.showMap = false;
			},
      constructUrl(pageNumber) {
        let baseUrl = '/';
        switch (this.type) {
          case 'hotel':
            baseUrl = '/hotel-de-charme';
            break;
          case 'appartment':
            baseUrl = '/appartement-de-vacances';
            break;
          case 'other':
            baseUrl = '/logement-insolite-de-luxe';
            break;
          case 'guest':
            baseUrl = '/chambres-dhotes-de-charme';
            break;
          case 'villa':
            baseUrl = '/villa-de-charme';
            break;
		case 'villa':
			baseUrl = '/les-nouveautes-hortense';
			break;
          case 'destinations':
            baseUrl = window.location.pathname;
            break;
		case 'collections':
			baseUrl = window.location.pathname;
			break;
		case 'weekends':
			baseUrl = window.location.pathname;
			break;	
          default:
            baseUrl = '/reservation-hotel';
        }
        return `${baseUrl}?page=${pageNumber}`;
      },
      fetchLodgings(pageNumber) {
        let params = {
        };
		const urlParams = new URLSearchParams(window.location.search);
		const startDateParam = urlParams.get('start_date');
    	const endDateParam = urlParams.get('end_date');
		if (startDateParam && endDateParam && startDateParam !== 'null' && endDateParam !== 'null') {
			params.start_date = startDateParam
			params.end_date = endDateParam
		}
        if (this.koediaFrom) {
          params.start_date = this.koediaFrom;
        }
        if (this.koediaTo) {
          params.end_date = this.koediaTo;
        }
        this.page = pageNumber;
        const url = this.constructUrl(pageNumber);
        fetcher.get(url, { params })
            .then(response => {
              this.filteredLodgings = response.data;
              this.scrollToTop();
            })
            .catch(error => {
              console.error('Failed to fetch lodgings:', error);
            });
      },
      handleScroll() {
        const searchBar = this.$refs.searchBar;
        const offsetTop = searchBar.offsetTop;

        if (window.pageYOffset > offsetTop) {
          searchBar.classList.add('sticky');
        } else {
          searchBar.classList.remove('sticky');
        }
      },
      updateDates(dates) {
        if(dates && dates.length > 0) {
          this.koediaFrom = this.formatDate(dates[0]);
          this.koediaTo = this.formatDate(dates[1]);
          this.from = this.koediaFrom;
          this.to = this.koediaTo;
          localStorage.setItem('reservationState', JSON.stringify({}));
          const state = {
            koediaFrom: this.koediaFrom,
            koediaTo: this.koediaTo,
            from: this.from,
            to: this.to,
            adults: 2,
            childs: 0
          };
          localStorage.setItem('reservationState', JSON.stringify(state));
        }
      },
      formatDate(date) {
        const dateBits = date.split("/");
        return `${dateBits[2]}-${dateBits[1]}-${dateBits[0]}`;
      },
      updateFilteredLodgings(newLodgings) {
        this.filteredLodgings = newLodgings.data;
        this.lodgings = newLodgings.data;
        this.pagedNumber = newLodgings.headers['x-lodgings-count'];
      },
			hideSmallMap(){
			   this.showMapCard = !this.showMapCard;
			   const mapfinds = document.querySelectorAll('[id="map-find"]');
			   mapfinds.forEach((map) => {
					map.remove();
				});

				const maps = document.querySelectorAll('[id="map"]');
			    maps.forEach((map) => {
					map.remove();
				});
			},
			async fetchWishlist() {
				try {
					if (this.user) {
						const response = await fetcher.get('/wishlists.json');
						this.wishlistLodgings = response.data.lodgings;
					}
				} catch (e) {
					console.error(e);
				}
			},
			scrollToTop() {
				window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
				});
			},
			setPages() {
        this.page_number_count = this.page;
				this.page = 1;
			    this.pages = [];
				let numberOfPages = Math.ceil((this.pagedNumber ? this.pagedNumber : this.filteredLodgings.length) / this.perPage); //this.paged_number;
				for (let index = 1; index <= numberOfPages; index++) {
					this.pages.push(index);
				}
		   },
		   paginate(lodgs) {
			    let page = this.page;
			    let perPage = this.perPage;
			    let from = (page * perPage) - perPage;
			    let to = (page * perPage);
			    return  lodgs.slice(from, to);
		    },
			setData(val, name) {
				// as for animal switch for example
				if (typeof val === 'boolean') {
					this.activeFilters[name] = val;
					return;
				}
				// all unchecked means we actually want all of them
				if (Array.isArray(val) && val.length === 0) {
					this.activeFilters[name] = [...this.allValues[name]];
				} else {
					this.activeFilters[name] = val;
				}
			},
			showFilters() {
				const filter = document.querySelector('#filter-lodging');
				if (filter.style.display === 'block') {
					this.showFilt = false;
					filter.style.display = 'none';
				} else {
					filter.style.display = 'block';
					this.showFilt = true;
				}
			},
			isUserConnected() {
				return this.user ? this.user : null;
			},
			sortByNote() {
				this.filteredLodgings.sort((a, b) => {
					if (this.asc) {
						return a.score - b.score;
					} else {
						return b.score - a.score;
					}
				});
				this.asc = !this.asc;
			},
			filter() {
				console.log('IN FILTER');
				this.filteredLodgings = this.lodgingsList;

				Object.entries(this.activeFilters).forEach(([filter, value]) => {
					// attributes with a unique value
					if (['score', 'standing', 'type'].includes(filter)) {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							return value.includes(lodging[filter]?.toString());
						});
					}

					// attributes with multiple possible values
					if (['styles', 'services', 'desires'/*, 'beddings'*/].includes(filter)) {
            if(value && value.length !== 28) {
              this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
                return this.includesIn(value, lodging[filter + '_arr']);
              });
            }
					}
					console.log('LODGINGS BEFORE');
					console.log('filter');
					console.log(filter);
          console.log('value');
          console.log(value);
					console.log(this.filteredLodgings);
					// remove lodgings that don't offer a pricing for it
					//if (['breakfast_include', 'half_pension', 'full_pension', 'all_inclusive'].includes(filter)) {
					//	this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
					//		if (value === false) return true;

					//		return lodging[filter + '_pricings'].some((pricing) => pricing > 0);
					//	});
					//}
					console.log('LODGINGS AFTER');
					console.log('filter');
					console.log(filter);
					console.log(this.filteredLodgings);
					// number range
					if (filter === 'budget') {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							//if (!lodging.pricings_arr.length) return true;

							//return lodging.pricings_arr.some((price) => price >= value[0] && price <= value[1]);
							return lodging.lowest_price >= value[0] && lodging.lowest_price <= value[1];
						});
					}

          if (filter === 'adults') {
            this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
              return lodging.accommodation_capacity == null || lodging.accommodation_capacity >= value || lodging.accommodation_capacity == 0;
            });
          }

					// toggle button, only filter when toggled
					if (filter === 'animal') {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							if (value === false) return true;

							return lodging.lodging_profile.animal === true;
						});
					}

          if (filter === 'delice') {
            this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
              if (value === false) return true;

              return lodging.lodging_profile.delice === true;
            });
          }

          if (filter === 'escapade') {
            this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
              if (value === false) return true;

              return lodging.lodging_profile.escapade === true;
            });
          }

          if (filter === 'evasion') {
            this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
              if (value === false) return true;

              return lodging.lodging_profile.evasion === true;
            });
          }

          if (filter === 'plaisirs') {
            this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
              if (value === false) return true;

              return lodging.lodging_profile.plaisirs === true;
            });
          }

          if (filter === 'kids') {
            this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
              return this.includesIn('Kids-friendly', lodging['services_arr']);
            });
          }

          if (filter === 'station') {
            this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
              return this.includesIn('Gare à promixité', lodging['services_arr']);
            });
          }

					// means only Leonie collection
					if (filter === 'business') {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							if (value === false) return true;

							return lodging.collections_arr.includes('Léonie');
						});
					}

					// free when canceling price is either nil or 0
					if (filter == 'freeCancel') {
						this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
							if (value === false) return true;

							return lodging.lodging_description.is_club
						});
					}

          if (['eq_services'].includes(filter)) {
            this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
              return this.includesInValue(value[0], lodging['services_arr']);
            });
          }
				});

				this.setPages();
			},
			formatLodgings() {
				let hebergement = ' hébergement';
				if ((this.pagedNumber ? this.pagedNumber : this.lodgingsCounter) > 1) {
					hebergement += 's';
				}
				return (this.pagedNumber ? this.pagedNumber : this.lodgingsCounter) + hebergement;
			},
      includesInValue(value = 0, array2) {
        if (!array2.length) {
          return value === 0 || value === undefined;
        }
        if (value === 0 || value === undefined) {
          return true;
        }
        return array2.map(String).includes(String(value));
      },
      selectCard(attr) {
        const cards = document.querySelectorAll('#cardProduct-big');
        const selectedCard = Array.from(cards).find(node => node.dataset.lodging === attr.toString());
        this.selectedCard = selectedCard;
        this.selectedMarker = attr;
        cards.forEach((el) => {
          if (el?.dataset?.marker !== selectedCard?.dataset?.marker) {
            el.style.display === 'none' ? (el.style.display = 'flex') : (el.style.display = 'none');
          }
        });
        this.selectedLodging = this.previous_lodging.find((lod) => {
          return lod.id === parseInt(attr);
        });
      },
			selectCardMap(attr){
				// this.selectCard(attr);
				if(this.showMap){
					setTimeout(() => {this.showMap = false}, 10);
					setTimeout(() => {this.showMap = true}, 10);
				}
			},
			displayMap() {
				this.showMap = !this.showMap;
				this.markers = this.markerMap();
			},
			includesIn(array1, array2) {
				// don't filter out this lodging when services, beddings, styles, or desires are not set
				if (!array2.length || !array1.length) return true;

				array1 = array1.map((e) => e?.toString());
				array2 = array2.map((e) => e?.toString());
				for (let val of array1) {
					if (array2.includes(val)) return true;
				}
				return false;
			},
			markerMap() {
				const markers = [];
				this.previous_lodging.map((el) => {
					markers.push([el.longitude, el.latitude, el.id, el.lowest_price]);
				});
				return markers;
			},
			fetchAllLodgings() {
				fetcher.get('/all_online_lodgings')
				.then((response) => {
					this.previous_lodging = response.data;
				})
				.catch((error) => {
					console.error(error);
				})
				.finally(() => {
					this.loading = false;
				});
  			}
		},
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'view_item_list',
        item_list_name: this.getGroupTitle,
        item_list_id: 'lodgings'
      });
	  this.fetchAllLodgings();	
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    created() {
			AOS.init();
			this.stylesMount = this.styles;
            this.servicesMount = this.services;
            this.desiresMount = this.desires;
		},
	};
</script>
<style lang="scss" scoped>
.page-item{
	border-color: black;
        button{
		display: inline-block;
		color: black;
	}
}
.active-item{
	color: green;
	border-color: black;
	border-style: solid;
}
.cta{
	margin: 0px auto;
	display: inline
}


#search-bannerr.sticky {
  position: fixed;
  top: 40px;
  width: 80%;
  z-index: 1000;
  background: transparent;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .custom-index {
        margin-top: 13vh !important;
    }
	#lodging-all .desti-header {
    margin-bottom: 0.5rem !important;
}
.lodge-title{
	font-size: 30px !important;
}
.lodge-description{
	font-size: 18px Im !important;
}
.btn-filters {
	margin-top: 1px !important;
}
}
.full-page-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.modal-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1001;
}

.close-btn {
  font-size: 30px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
}
@media only screen and (max-width: 480px) {
  .close-btn {
    font-size: 24px;
    padding: 5px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1002;
  }
  #map-test {
  flex: 1;
  width: 100%;
	height: 100%;
	}

	.mapboxgl-canvas {
	width: 100% !important;
	height: 100% !important;
	}

}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

</style>