<template>
    <div id="tips" :class="{'desktop-margin': !isPhone}">
      <div class="container-fluid py-2">
      <div class="d-flex flex-row flex-nowrap row-weekend mb-5" id="scrollerr"  style="overflow: scroll; overflow-y: hidden">
          <div class="col-proposition col-weekend">
            <div class="card-propositionn" data-aos="fade-up" data-aos-duration="1000">
              <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/calendrier.png" v-set-alt class="mb-2">
              <p class="anchor mt-2 mb-4">Offre valable pour tout séjour effectué entre le 1er avril 2025 et le 5 mai 2025 sur le site hortense.green</p>
            </div>
          </div>
          <div class="col-proposition col-weekend">
          <div class="card-propositionn" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/offre+valable.png" v-set-alt class="mb-2">
            <p class="anchor mt-2 mb-4">Offre valable pour toutes les réservations effectuées avant le 1er mars 2025</p>
          </div>
        </div>
          <div class="col-proposition col-weekend">          
            <div class="card-propositionn" data-aos="fade-up" data-aos-duration="1000">
              <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/nuit.png" v-set-alt class="mb-2">
              <p class="anchor mt-2 mb-4">Offre valable pour tout séjour de 2 nuits minimum</p>
            </div>
          </div>
          <div class="col-proposition col-weekend">
          <div class="card-propositionn" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/fide%CC%81lite%CC%81.png" v-set-alt class="mb-2">
            <p class="anchor mt-2 mb-4">Offre réservée aux membres du programme de fidélité Hortensia</p>
          </div>
        </div>
        <div class="col-proposition col-weekend">
          <div class="card-propositionn" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/pourcentage.png" v-set-alt class="mb-2">
            <p class="anchor mt-2 mb-4">Offre non cumulable avec d’autres promotions ou offres en cours</p>
          </div>
        </div>
        </div>
      </div>
      <div class="mt-5"></div>
    </div>
  </template>
  
  <script>
    import AOS from 'aos';
  
    export default {
      name: 'discount-prepositions',
      data() {
        return {
          cssProps: {
            backgroundImage: `url(${require('../images/icotype.svg')})`
          }
        }
      },
      methods: {
        scroll_left() {
          let content = document.querySelector("#scrollerr");
          content.scrollLeft -= 220;
        },
        scroll_right() {
          let content = document.querySelector("#scrollerr");
          content.scrollLeft += 220;
        }
      },
      mounted() {
        AOS.init();
      },
      computed: {
        isPhone() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
          return true;
        }else{
          return false;
        }
       }
      },
    }
  </script>
  
  
  <style lang="scss" scope='app/assets/stylesheets/components/tips'>
  
  </style>
  <style lang="scss" scoped>
    .desktop-margin {
      margin-top: 1rem !important;
    }
    .center{
      text-align: center;
    }
    .anchor{
        text-decoration: none;
          font-family: $Raleway;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          text-align: center;

    }
    .subtitle{
      width: 670px;
    }
    .element{
      width: 240px;
    }
    .card-propositionn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    object-fit: cover;
  }
}

.anchor {
  text-align: center;
}

    .tips-margin-top {
      margin-top: 3rem;
    }
  </style>
  
  