<template>
    <div id="home">
      <SpringBanner v-if="!isPhone" :destinations="destinations"></SpringBanner>
      <SpringBannerPhone v-else :destinations="destinations"></SpringBannerPhone>
      <Searchbtn
        @show="showBanner = !showBanner"
        @top="redirectToTop"
      ></Searchbtn>
      <Searchbanner v-if="showBanner && !isPhone" @close="showBanner = false"></Searchbanner>
      <SpringPrepositions v-if="!isPhone"></SpringPrepositions>
      <SpringPrepositionsPhone v-else></SpringPrepositionsPhone>
      <div class="df-jcc-aic">
          <a :href="'/reservation-hotel?base=spring'" target="_blank" class="hortense-btn-primary"
            >Réservez vos vacances maintenant</a
          >
      </div>
      <div class="kids-cornerrr mt-3 p-5" style="background-color: #E6E1DB;">
        <div class="leftt-section mt-1">
        </div>
        <div class="content mt-1">
        <div v-if="!isPhone" class="description">
            <h2 class="merriweather-italic-24 mt-1 mb-2">Le Printemps selon vos envies</h2>
            <p class="raleway-reg-p16">Pour vos vacances de printemps, partez à la découverte des plus belles régions de France grâce à notre sélection d’hébergements uniques. Que vous rêviez d’une escapade au bord de la mer, d’un séjour au cœur des vignobles ou d’un week-end dans une campagne paisible, Hortense vous ouvre les portes de destinations variées et inspirantes.<br><br>
            Explorez la Nouvelle-Aquitaine avec ses plages sauvages et ses prestigieux vignobles, ou laissez-vous séduire par la Bourgogne-Franche-Comté, où nature verdoyante et patrimoine historique se rencontrent. Découvrez également le charme bucolique du Centre-Val de Loire, célèbre pour ses majestueux châteaux, ou les paysages marins et bocages apaisants de la Normandie.<br><br>
            Notre carnet d’adresses s’étend sur l’ensemble du territoire français, offrant des séjours adaptés à toutes vos envies. Ce printemps, vivez une expérience authentique et mémorable, où que vos envies vous mènent.
            </p>
        </div>
        <div v-else class="description">
            <h2 class="merriweather-italic-24 mt-1 mb-2">Le Printemps selon vos envies</h2>
            <p class="raleway-reg-p15">
                <span v-if="!showMore">
                    {{ truncatedText(170, this.lodging.lodging_description.lodging_discover) }}
                </span>
                <span v-else>
                    {{ this.lodging.lodging_description.lodging_discover }}
                </span>
            </p>

            <button @click="toggleText" class="show-more-btn">
                {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
            </button>
        </div>
        </div>
      </div>
      <GardPrepositions v-if="!isPhone"></GardPrepositions>
      <GardPrepositionsPhone v-else></GardPrepositionsPhone>
      <div class="df-jcc-aic mt-3">
          <a :href="'/reservation-hotel?base=spring'" target="_blank" class="hortense-btn-primary"
            >Découvrir nos destinations</a
          >
      </div>
      <div class="kids-cornerrr mt-3 p-5" style="background-color: #E6E1DB;">
        <div class="content mt-1">
        <div v-if="!isPhone" class="description">
            <h2 class="merriweather-italic-24 mt-1 mb-2">Une sélection d’hébergements uniques pour vos vacances de printemps</h2>
            <p class="raleway-reg-p16">Vos vacances de printemps méritent une expérience unique, et Hortense est là pour vous accompagner. Que vous rêviez de détente dans un hôtel de charme, de moments privilégiés dans une maison ou une villa privée, ou encore d’une immersion en pleine nature dans un lodge ou un lieu insolite, nous avons l’hébergement parfait pour vous.<br><br>
            Pour les amateurs d’authenticité, nos chambres d’hôtes vous plongent dans une ambiance locale chaleureuse, avec des hôtes passionnés prêts à partager leurs meilleures adresses. Chaque lieu a été soigneusement sélectionné pour son caractère unique, son confort et son engagement écologique.<br><br>
            Ce printemps, offrez-vous des vacances à votre image. Que vous voyagiez en couple, en famille ou entre amis, Hortense transforme vos vacances de printemps en une expérience mémorable, adaptée à vos envies.<br>
            </p>
        </div>
        <div v-else class="description">
            <h2 class="merriweather-italic-24 mt-1 mb-2">Une sélection d’hébergements uniques pour vos vacances de printemps</h2>
            <p class="raleway-reg-p15">
                <span v-if="!showMoreTwo">
                    {{ truncatedText(170, this.lodging.lodging_description_two.lodging_discover) }}
                </span>
                <span v-else>
                    {{ this.lodging.lodging_description_two.lodging_discover }}
                </span>
            </p>

            <button @click="toggleTextTwo" class="show-more-btn">
                {{ showMoreTwo ? 'Afficher moins' : 'Afficher plus' }}
            </button>
        </div>
        </div>
        <div class="right-section mt-1">
        </div>
      </div>
      <HotelPrepositions v-if="!isPhone"></HotelPrepositions>
      <HotelPrepositionsPhone v-else></HotelPrepositionsPhone>
      <div class="df-jcc-aic mt-3">
          <a :href="'/reservation-hotel?base=spring'" target="_blank" class="hortense-btn-primary"
            >Découvrir notre sélection</a
          >
      </div>
      <div class="container mt-3" style="background-color: #E6E1DB; text-align: center; justify-content: center; align-items: center; max-width: 100% !important">
      <div class="promo-section">
        <h2 v-if="!isPhone" class="merriweather-italic-24">Profitez de notre offre Early Bird et préparez vos vacances de printemps dès maintenant !</h2>
        <h1 v-else class="merriweather-italic-24">Profitez de notre offre Early Bird et préparez vos vacances de printemps dès maintenant !</h1>
        <p class="promo-text raleway-reg-p16">Du 1er avril au 5 mai 2025, vivez des vacances de Pâques d’exception alliant bien-être, découvertes gastronomiques, immersion locale et services exclusifs. Nos hébergements soigneusement sélectionnés vous garantissent des instants mémorables dans des cadres enchanteurs.
        </p>
        <div class="promo-box">
            CODE EXCLUSIF<br>
            <span style="font-size: 20px; font-weight: bold;">ENVIEDEPRINTEMPS</span>
        </div>
        <p class="promo-text raleway-reg-p16">Réservez avant le 15 mars 2025 et bénéficiez de 15 % sur votre séjour avec notre offre Early Bird.<br>
        Réservez dès maintenant et profitez de tarifs exclusifs sur des hébergements de charme en France.<br>
        Ne tardez pas, les plus belles adresses partent vite !
        </p>
        </div>
    </div>
    <DiscountPrepositions v-if="!isPhone"></DiscountPrepositions>
    <DiscountPrepositionsPhone v-else></DiscountPrepositionsPhone>
    <div class="df-jcc-aic">
          <a :href="'/reservation-hotel?base=spring'" target="_blank" class="hortense-btn-primary"
            >Profitez de l'offre</a
          >
      </div>
    </div>
  </template>
  
  <script>
  import fetcher from "../packs/shared/api.js";
  import Searchbtn from "../components/btns/Searchbtn.vue";
  import Searchbanner from "../components/btns/Searchbanner.vue";
  import SpringPrepositions from "../components/SpringPrepositions.vue";
  import GardPrepositions from "../components/GardPrepositions.vue";
  import HotelPrepositions from "../components/HotelPrepositions.vue";
  import DiscountPrepositions from "../components/DiscountPrepositions.vue";
  import SpringBanner from "../components/shared/SpringBanner.vue";
  import SpringBannerPhone from "../components/SpringBannerPhone.vue";
  import SpringPrepositionsPhone from "../components/SpringPrepositionsPhone.vue";
  import GardPrepositionsPhone from "../components/GardPrepositionsPhone.vue";
  import HotelPrepositionsPhone from "../components/HotelPrepositionsPhone.vue"
  import DiscountPrepositionsPhone from "../components/DiscountPrepositionsPhone.vue";

  export default {
    name: "spring",
    props: ["user"],
    components: {
    Searchbtn,
    Searchbanner,
    SpringPrepositions,
    GardPrepositions,
    HotelPrepositions,
    DiscountPrepositions,
    SpringBanner,
    SpringBannerPhone,
    SpringPrepositionsPhone,
    GardPrepositionsPhone,
    HotelPrepositionsPhone,
    DiscountPrepositionsPhone
},
    data() {
      return {
        lodging: {
        lodging_description: {
          lodging_discover: `Pour vos vacances de printemps, partez à la découverte des plus belles régions de France grâce à notre sélection d’hébergements uniques. Que vous rêviez d’une escapade au bord de la mer, d’un séjour au cœur des vignobles ou d’un week-end dans une campagne paisible, Hortense vous ouvre les portes de destinations variées et inspirantes.

          Explorez la Nouvelle-Aquitaine avec ses plages sauvages et ses prestigieux vignobles, ou laissez-vous séduire par la Bourgogne-Franche-Comté, où nature verdoyante et patrimoine historique se rencontrent. Découvrez également le charme bucolique du Centre-Val de Loire, célèbre pour ses majestueux châteaux, ou les paysages marins et bocages apaisants de la Normandie.

          Notre carnet d’adresses s’étend sur l’ensemble du territoire français, offrant des séjours adaptés à toutes vos envies. Ce printemps, vivez une expérience authentique et mémorable, où que vos envies vous mènent.`
        },
        lodging_description_two: {
          lodging_discover: `Vos vacances de printemps méritent une expérience unique, et Hortense est là pour vous accompagner. Que vous rêviez de détente dans un hôtel de charme, de moments privilégiés dans une maison ou une villa privée, ou encore d’une immersion en pleine nature dans un lodge ou un lieu insolite, nous avons l’hébergement parfait pour vous.

          Pour les amateurs d’authenticité, nos chambres d’hôtes vous plongent dans une ambiance locale chaleureuse, avec des hôtes passionnés prêts à partager leurs meilleures adresses. Chaque lieu a été soigneusement sélectionné pour son caractère unique, son confort et son engagement écologique.

          Ce printemps, offrez-vous des vacances à votre image. Que vous voyagiez en couple, en famille ou entre amis, Hortense transforme vos vacances de printemps en une expérience mémorable, adaptée à vos envies.`
        }
      },
        showBanner: false,
        lodgings: null,
        weekends: null,
        exclusives: null,
        experiences: null,
        destinations: null,
        collections: null,
        evergreens: null,
        wishlist: null,
        accommodations: null,
        showMore: false,
        showMoreTwo: false,
        hasScrolled: false
      };
    },
    methods: {
      toggleText() {
      this.showMore = !this.showMore;
    },
    handleScroll() {
      const bPhone = document.getElementById("b-phone");
      const navPhone = document.getElementById("nav-phone");

      if (bPhone && navPhone) {
        const rect = bPhone.getBoundingClientRect();
        if (rect.bottom <= 0) {
          navPhone.style.display = "flex";
        } else {
          navPhone.style.display = "none";
        }
      }
    },
    toggleTextTwo() {
      this.showMoreTwo = !this.showMoreTwo;
    },
      truncatedText(textLength, text_content) {
      const text = text_content;
      const maxLength = textLength;
      if (text && text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    },
      openModal() {
        this.$root.$emit("openModal");
      },
      redirectToTop() {
        window.scrollTo(0, "#navbar");
      },
      async fetchdatas() {
        try {
          this.lodgings = [];
          const responseAccommodation     = await fetcher.get(
              "/home_lodgings"
          );
          this.accommodations = responseAccommodation.data;
  
          const responseWeekend = await fetcher.get("/weekends/");
          this.weekends = await responseWeekend.data;
          const responseExclusive = await fetcher.get("/offres-exclusives/");
          this.exclusives = await responseExclusive.data;
          const responseExperience = await fetcher.get("/basic/experiences/");
          this.experiences = responseExperience.data;
          const responseDestination = await fetcher.get("/destination");
          this.destinations = await responseDestination.data;
          const responseCollection = await fetcher.get("/collections/");
          this.collections = responseCollection.data;
          const responseEvergreen = await fetcher.get(
            "/programme-evergreen-demarche-environnementale/"
          );
          this.evergreens = await responseEvergreen.data;
          if (this.user) {
            const responseWishlist = await fetcher.get("/wishlists");
            this.wishlist = responseWishlist.data;
          }
        } catch (e) {
          console.error(e);
        }
      },
    },
    mounted() {
      this.fetchdatas();
      const navPhone = document.getElementById("nav-phone");
    
    if (navPhone) {
      navPhone.style.display = "none";
    }

    window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
    computed: {
        isPhone() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            return true;
        }else{
            return false;
        }
        }
    },
  };
  </script>
  <style scoped>
    #search-bannerr.fixed {
    top: 4.5% !important;
    }
    .container-index {
      margin-top: 3vh !important;
    }
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .container-index {
      margin-top: 22vh !important;
    }
  }
  .kids-cornerrr {
  display: flex;
  flex-direction: row;
  }

  .leftt-section {
    flex: 1;
    background-image: url("https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances+paques+-+couple+-+photo+1.webp");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: white;
    text-align: center;
    padding: 1rem;
    min-height: 100%;
    height: auto;
  }
  .right-section {
    flex: 1;
    background-image: url("https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances+paques+-+famille+-+photo+2.webp");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: white;
    text-align: center;
    padding: 1rem;
    min-height: 100%;
    height: auto;
  }

  .content {
    flex: 1.2;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .description {
      p {
        margin-top: 10px;
        font-family: "Raleway";
      }

      .dynamic {
        list-style-type: disc;
        padding-left: 20px;
        font-family: "Raleway";
      }
    }
  }
  .promo-section {
            padding: 20px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 40px;
            padding-right: 40px;
            justify-content: center;
        }
        .promo-box {
            background-color: black;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            margin: 20px 0;
            font-size: 18px;
            font-family: "Raleway", sans-serif;
            border-radius: 5px;
        }
        .promo-text {
            font-size: 16px;
            color: black;
            line-height: 1.6;
            font-family: "Raleway", sans-serif;
        }
        @media (max-width: 768px) {
  .kids-cornerrr {
    flex-direction: column-reverse; 
  }

  .leftt-section, .right-section {
    min-height: 300px;
    width: 100%;
  }

  .content {
    padding: 15px;
    text-align: left;
  }

  .description {
    padding: 10px;
  }
}
.show-more-btn {
  color: #3B6152;
}

@media only screen and (max-device-width: 480px) {
    .promo-section {
            text-align: left;
        }
  }
  .merriweather-regg-40 {
        font-family: "Merriweather";
        font-weight: 400;
        font-size: 40px;
     }
  </style>
  