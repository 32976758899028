<template>
    <div id="home">
      <SummerBanner v-if="!isPhone" :destinations="destinations"></SummerBanner>
      <SummerBannerPhone v-else :destinations="destinations"></SummerBannerPhone>
      <Searchbtn
        @show="showBanner = !showBanner"
        @top="redirectToTop"
      ></Searchbtn>
      <Searchbanner v-if="showBanner" @close="showBanner = false"></Searchbanner>
      <SummerPrepositions  v-if="!isPhone"></SummerPrepositions>
      <SummerPrepositionsPhone v-else></SummerPrepositionsPhone>
      <div class="df-jcc-aic">
          <a :href="'/reservation-hotel?base=summer'" target="_blank" class="hortense-btn-primary"
            >Réservez vos vacances maintenant</a
          >
      </div>
      <div class="kids-cornerr mt-3 p-5" style="background-color: #E6E1DB;">
        <div class="leftt-section mt-1">
        </div>
        <div class="content mt-1">
        <div v-if="!isPhone" class="description">
            <h2 class="merriweather-italic-24 mt-1 mb-2">Votre Été, Vos Souvenirs</h2>
            <p class="raleway-reg-p16">Pour vos vacances, partez à la découverte des plus belles régions de France grâce à notre sélection d’hébergements uniques. Que vous rêviez d’une escapade au bord de la mer, d’un séjour au cœur des montagnes, d’un week-end dans une campagne paisible ou d’un city break dans une ville pleine de charme, Hortense vous ouvre les portes de destinations variées et inspirantes.<br><br>
            Notre carnet d’adresses s’étend sur l’ensemble du territoire français, offrant des séjours adaptés à tous les goûts et toutes les envies. Chaque destination a été choisie pour son caractère authentique, son patrimoine naturel ou culturel et l’engagement écoresponsable de ses hébergements.<br><br>
            Avec Hortense, explorez la richesse des paysages français, des plages sauvages de Bretagne aux champs de lavande en Provence, des sommets des Alpes aux vignobles de Bordeaux. Quelle que soit la région qui vous fait rêver, nous avons l’adresse parfaite pour vous accueillir.
            </p>
        </div>
        <div v-else class="description">
            <h2 class="merriweather-italic-24 mt-1 mb-2">Votre Été, Vos Souvenirs</h2>
            <p class="raleway-reg-p15">
                <span v-if="!showMore">
                    {{ truncatedText(170, this.lodging.lodging_description.lodging_discover) }}
                </span>
                <span v-else>
                    {{ this.lodging.lodging_description.lodging_discover }}
                </span>
            </p>

            <button @click="toggleText" class="show-more-btn">
                {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
            </button>
        </div>
        </div>
      </div>
      <LiuexPrepositions v-if="!isPhone"></LiuexPrepositions>
      <LiuexPrepositionsPhone v-else></LiuexPrepositionsPhone>
      <div class="df-jcc-aic mt-3">
          <a :href="'/reservation-hotel?base=summer'" target="_blank" class="hortense-btn-primary"
            >Découvrir nos destinations</a
          >
      </div>
      <div class="kids-cornerr mt-3 p-5" style="background-color: #E6E1DB;">
        <div class="content mt-1">
        <div v-if="!isPhone" class="description">
            <h2 class="merriweather-italic-24 mt-1 mb-2">Une sélection d’hébergements uniques pour vos vacances d’été</h2>
            <p class="raleway-reg-p16" style="padding-right: 10px;">Vos vacances d’été méritent une expérience unique, et Hortense est là pour vous accompagner. Que vous rêviez de détente dans un hôtel de charme, de moments privilégiés dans une maison ou une villa privée, ou encore d’une immersion en pleine nature dans un lodge ou un lieu insolite, nous avons l’hébergement parfait pour vous.<br><br>
            Pour les amateurs d’authenticité, nos chambres d’hôtes vous plongent dans une ambiance locale chaleureuse, avec des hôtes passionnés prêts à partager leurs meilleures adresses. Chaque lieu a été soigneusement sélectionné pour son caractère unique, son confort et son engagement écologique.<br><br>
            Cet été, offrez-vous des vacances à votre image. Que vous voyagiez en couple, en famille ou entre amis, Hortense transforme vos vacances d’été en une expérience mémorable, adaptée à vos envies.
            </p>
        </div>
        <div v-else class="description">
            <h2 class="merriweather-italic-24 mt-1 mb-2">Une sélection d’hébergements uniques pour vos vacances d’été</h2>
            <p class="raleway-reg-p15">
                <span v-if="!showMoreTwo">
                    {{ truncatedText(170, this.lodging.lodging_description_two.lodging_discover) }}
                </span>
                <span v-else>
                    {{ this.lodging.lodging_description_two.lodging_discover }}
                </span>
            </p>

            <button @click="toggleTextTwo" class="show-more-btn">
                {{ showMoreTwo ? 'Afficher moins' : 'Afficher plus' }}
            </button>
        </div>
        </div>
        <div class="right-section mt-1">
        </div>
      </div>
      <ChambrePrepositions v-if="!isPhone"></ChambrePrepositions>
      <ChambrePrepositionsPhone v-else></ChambrePrepositionsPhone>
      <div class="df-jcc-aic mt-3">
          <a :href="'/reservation-hotel?base=summer'" target="_blank" class="hortense-btn-primary"
            >Découvrir notre sélection</a
          >
      </div>
    <div class="container mt-3" style="background-color: #E6E1DB; text-align: center; justify-content: center; align-items: center; max-width: 100% !important">
      <div class="promo-section">
        <h2 v-if="!isPhone" class="merriweather-italic-24">Profitez de notre offre Early Bird et préparez vos vacances d’été dès maintenant !</h2>
        <h1 v-else class="merriweather-italic-24">Profitez de notre offre Early Bird et préparez vos vacances d’été dès maintenant !</h1>
        <p class="promo-text raleway-reg-p16">Du 1er juin au 31 août 2025, vivez des vacances d’été d’exception alliant bien-être, découvertes gastronomiques, immersion locale et services exclusifs. Nos hébergements soigneusement sélectionnés vous garantissent des instants mémorables dans des cadres enchanteurs.</p>
        <div class="promo-box">
            CODE EXCLUSIF<br>
            <span style="font-size: 20px; font-weight: bold;">ETEAUTREMENT</span>
        </div>
        <p class="promo-text raleway-reg-p16">Réservez avant le 1er avril 2025 et bénéficiez de 15 % sur votre séjour avec notre offre Early Bird.<br>
          Réservez dès maintenant et profitez de tarifs exclusifs sur des hébergements de charme en France.<br>
          Ne tardez pas, les plus belles adresses partent vite !
        </p>
        </div>
    </div>
    <VoucherPrepositions v-if="!isPhone"></VoucherPrepositions>
    <VoucherPrepositionsPhone v-else></VoucherPrepositionsPhone>
    <div class="df-jcc-aic">
          <a :href="'/reservation-hotel?base=summer'" target="_blank" class="hortense-btn-primary"
            >Profitez de l'offre</a
          >
      </div>
    </div>
  </template>
  
  <script>
  import fetcher from "../packs/shared/api.js";
  import Banner from "../components/shared/Banner.vue";
  import Searchbtn from "../components/btns/Searchbtn.vue";
  import Searchbanner from "../components/btns/Searchbanner.vue";
  import SummerPrepositions from "../components/SummerPrepositions.vue";
  import VoucherPrepositions from "../components/VoucherPrepositions.vue";
  import SummerPrepositionsPhone from "../components/SummerPrepositionsPhone.vue";
  import ChambrePrepositions from "../components/ChambrePrepositions.vue";
  import ChambrePrepositionsPhone from "../components/ChambrePrepositionsPhone.vue";
  import LiuexPrepositions from "../components/LiuexPrepositions.vue";
  import LiuexPrepositionsPhone from "../components/LiuexPrepositionsPhone.vue";
  import SummerBanner from "../components/shared/SummerBanner.vue";
  import SummerBannerPhone from "../components/SummerBannerPhone.vue";
  import VoucherPrepositionsPhone from "../components/VoucherPrepositionsPhone.vue";

  export default {
    name: "summer",
    props: ["user"],
    components: {
    Banner,
    Searchbtn,
    Searchbanner,
    SummerPrepositions,
    LiuexPrepositions,
    ChambrePrepositions,
    VoucherPrepositions,
    SummerPrepositionsPhone,
    ChambrePrepositions,
    LiuexPrepositions,
    LiuexPrepositionsPhone,
    ChambrePrepositionsPhone,
    SummerBanner,
    SummerBannerPhone,
    VoucherPrepositionsPhone
},
    data() {
      return {
        showBanner: false,
        lodgings: null,
        weekends: null,
        exclusives: null,
        experiences: null,
        destinations: null,
        collections: null,
        evergreens: null,
        wishlist: null,
        accommodations: null,
        lodging: {
        lodging_description: {
          lodging_discover: `Pour vos vacances, partez à la découverte des plus belles régions de France grâce à notre sélection d’hébergements uniques. Que vous rêviez d’une escapade au bord de la mer, d’un séjour au cœur des montagnes, d’un week-end dans une campagne paisible ou d’un city break dans une ville pleine de charme, Hortense vous ouvre les portes de destinations variées et inspirantes.

            Notre carnet d’adresses s’étend sur l’ensemble du territoire français, offrant des séjours adaptés à tous les goûts et toutes les envies. Chaque destination a été choisie pour son caractère authentique, son patrimoine naturel ou culturel et l’engagement écoresponsable de ses hébergements.

            Avec Hortense, explorez la richesse des paysages français, des plages sauvages de Bretagne aux champs de lavande en Provence, des sommets des Alpes aux vignobles de Bordeaux. Quelle que soit la région qui vous fait rêver, nous avons l’adresse parfaite pour vous accueillir.`
        },
        lodging_description_two: {
          lodging_discover: `Vos vacances d’été méritent une expérience unique, et Hortense est là pour vous accompagner. Que vous rêviez de détente dans un hôtel de charme, de moments privilégiés dans une maison ou une villa privée, ou encore d’une immersion en pleine nature dans un lodge ou un lieu insolite, nous avons l’hébergement parfait pour vous.

            Pour les amateurs d’authenticité, nos chambres d’hôtes vous plongent dans une ambiance locale chaleureuse, avec des hôtes passionnés prêts à partager leurs meilleures adresses. Chaque lieu a été soigneusement sélectionné pour son caractère unique, son confort et son engagement écologique.

            Cet été, offrez-vous des vacances à votre image. Que vous voyagiez en couple, en famille ou entre amis, Hortense transforme vos vacances d’été en une expérience mémorable, adaptée à vos envies.`
        }
      },
      showMore: false,
      showMoreTwo: false,
      };
    },
    methods: {
      toggleText() {
      this.showMore = !this.showMore;
    },
    toggleTextTwo() {
      this.showMoreTwo = !this.showMoreTwo;
    },
    handleScroll() {
      const bPhone = document.getElementById("b-phone");
      const navPhone = document.getElementById("nav-phone");

      if (bPhone && navPhone) {
        const rect = bPhone.getBoundingClientRect();
        if (rect.bottom <= 0) {
          navPhone.style.display = "flex";
        } else {
          navPhone.style.display = "none";
        }
      }
    },
      truncatedText(textLength, text_content) {
      const text = text_content;
      const maxLength = textLength;
      if (text && text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    },
      openModal() {
        this.$root.$emit("openModal");
      },
      redirectToTop() {
        window.scrollTo(0, "#navbar");
      },
      async fetchdatas() {
        try {
          this.lodgings = [];
          const responseAccommodation     = await fetcher.get(
              "/home_lodgings"
          );
          this.accommodations = responseAccommodation.data;
  
          const responseWeekend = await fetcher.get("/weekends/");
          this.weekends = await responseWeekend.data;
          const responseExclusive = await fetcher.get("/offres-exclusives/");
          this.exclusives = await responseExclusive.data;
          const responseExperience = await fetcher.get("/basic/experiences/");
          this.experiences = responseExperience.data;
          const responseDestination = await fetcher.get("/destination");
          this.destinations = await responseDestination.data;
          const responseCollection = await fetcher.get("/collections/");
          this.collections = responseCollection.data;
          const responseEvergreen = await fetcher.get(
            "/programme-evergreen-demarche-environnementale/"
          );
          this.evergreens = await responseEvergreen.data;
          if (this.user) {
            const responseWishlist = await fetcher.get("/wishlists");
            this.wishlist = responseWishlist.data;
          }
        } catch (e) {
          console.error(e);
        }
      },
    },
    mounted() {
      this.fetchdatas();
      const navPhone = document.getElementById("nav-phone");
    
    if (navPhone) {
      navPhone.style.display = "none";
    }

    window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
    computed: {
        isPhone() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            return true;
        }else{
            return false;
        }
        }
    },
  };
  </script>
  <style scoped>
    #search-bannerr.fixed {
    top: 4.5% !important;
    }
  .container-index {
      margin-top: 3vh !important;
    }
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .container-index {
      margin-top: 22vh !important;
    }
  }
  .kids-cornerr {
  display: flex;
  flex-direction: row;
  }

  .leftt-section {
    flex: 1;
    background-image: url("https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances+paques+-+couple+-+photo+1.webp");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: white;
    text-align: center;
    padding: 1rem;
    min-height: 100%;
    height: auto;
  }
  .right-section {
    flex: 1;
    background-image: url("https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/vacances+paques+-+famille+-+photo+2.webp");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: white;
    text-align: center;
    padding: 1rem;
    min-height: 100%;
    height: auto;
  }

  .content {
    flex: 1.2;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .description {
      p {
        margin-top: 10px;
        font-family: "Raleway";
      }

      .dynamic {
        list-style-type: disc;
        padding-left: 20px;
        font-family: "Raleway";
      }
    }
  }
  .promo-section {
            padding: 20px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 40px;
            padding-right: 40px;
            justify-content: center;
        }
        .promo-box {
            background-color: black;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            margin: 20px 0;
            font-size: 18px;
            font-family: "Raleway", sans-serif;
            border-radius: 5px;
        }
        .promo-text {
            font-size: 16px;
            color: #333;
            line-height: 1.6;
            font-family: "Raleway", sans-serif;
        }
        @media (max-width: 768px) {
  .kids-cornerr {
    flex-direction: column-reverse; 
  }

  .leftt-section, .right-section {
    min-height: 300px;
    width: 100%;
  }

  .content {
    padding: 15px;
    text-align: left;
  }

  .description {
    padding: 10px;
  }
}
.show-more-btn {
  color: #3B6152;
}
@media only screen and (max-device-width: 480px) {
    .promo-section {
            text-align: left;
        }
  }
  .merriweather-regg-40 {
        font-family: "Merriweather";
        font-weight: 400;
        font-size: 40px;
     }
  </style>
  