<template>
  <div id="tips" :class="{'desktop-margin': !isPhone}">
    <div class="titles center tips-margin-top">
    </div>
    <div class="container-fluid py-2">
      <div class="d-flex flex-row flex-nowrap row-weekend" id="scrollerr" style="overflow: scroll; overflow-y: hidden">
        
        <div 
          class="col-proposition col-weekend" 
          @click="openDestination('https://www.hortense.green/hotel-de-charme')"
          style="cursor: pointer;"
        >
          <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/ho%CC%82tels-vacances-paques.webp" v-set-alt class="mb-2">
          </div>
        </div>

        <div 
          class="col-proposition col-weekend" 
          @click="openDestination('https://www.hortense.green/villa-de-charme')" 
          style="cursor: pointer;"
        >          
          <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/villas-vacances-paques.webp" v-set-alt class="mb-2">
          </div>
        </div>

        <div 
          class="col-proposition col-weekend" 
          @click="openDestination('https://www.hortense.green/chambres-dhotes-de-charme')" 
          style="cursor: pointer;"
        >
          <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/chambres-hotes-vacances-paques.webp" v-set-alt class="mb-2">
          </div>
        </div>

        <div 
          class="col-proposition col-weekend" 
          @click="openDestination('https://www.hortense.green/logement-insolite-de-luxe')" 
          style="cursor: pointer;"
        >          
          <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
            <img src="https://conversions-api-gateway-9083319738648672-s3bucket-zfjcs6au7hlj.s3.eu-west-3.amazonaws.com/lieux-insolites-vacances-paques.webp" v-set-alt class="mb-2">
          </div>
        </div>

      </div>
    </div>
    <div class="mt-5"></div>
  </div>
</template>

<script>
import AOS from 'aos';

export default {
  name: 'hotel-propositions',
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require('../images/icotype.svg')})`
      }
    };
  },
  methods: {
    openDestination(baseUrl) {
      const baseParam = this.baseQuery ? `?base=${this.baseQuery}` : '';
      const finalUrl = `${baseUrl}${baseParam}`;
      window.open(finalUrl, '_blank');
    },
    scroll_left() {
      let content = document.querySelector("#scrollerr");
      content.scrollLeft -= 220;
    },
    scroll_right() {
      let content = document.querySelector("#scrollerr");
      content.scrollLeft += 220;
    }
  },
  computed: {
    isPhone() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
    },
    baseQuery() {
      const path = window.location.pathname;
      if (path.includes("/vacances-printemps")) {
        return 'spring';
      } else if (path.includes("/vacances-ete")) {
        return 'summer';
      }
      return '';
    }
  },
  mounted() {
    AOS.init();
  }
}
</script>

<style lang="scss" scope='app/assets/stylesheets/components/tips'></style>

<style lang="scss" scoped>
.desktop-margin {
  margin-top: 1rem !important;
}

.center {
  text-align: center;
}

.anchor {
  font-family: $Raleway;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}

.subtitle {
  width: 670px;
}

.element {
  width: 240px;
}

.card-proposition {
  img {
    width: 277px;
    height: 280px;
    object-fit: cover;
  }
}

.tips-margin-top {
  margin-top: 3rem;
}

#scrollerr {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.row-weekend {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.col-weekend {
  flex: 0 0 auto;
}

.card-proposition {
  width: 260px;
  text-align: center;
}
</style>
